import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PageTitle from "../elements/PageTitle";
import Button from "../elements/Button";
import PageDividerHr from "../elements/PageDividerHr";
import Message, { openMessage, renderMessageContent } from "../elements/Message";
import Card from "../elements/Card";
import api from "../utlities/api";
import Modal from "../elements/Modal";

const MessageList = ({ message_type, test = false, mini = false }) => {
    const [messages, setMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedMessageId, setSelectedMessageId] = useState(null);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [displayContent, setDisplayContent] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1199);
    const [smallMobile, setSmallMobile] = useState(window.innerWidth <= 576);
    const location = useLocation();

    useEffect(() => {
        const fetchMessages = async () => {
            setIsLoading(true);
            try {
                const data = test ? await api.get('/Messages') : getStaticMessages();
                setMessages(data);
            } catch (error) {
                console.error("Error fetching messages:", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchMessages();
    }, [message_type, test]);

    useEffect(() => {
        if (!mini) {
            setDisplayContent(null);

            const displayContainer = document.querySelector('.message-display');
            if (displayContainer) {
                displayContainer.style.position = 'static';
            }

            setSelectedMessageId(null);
            setSelectedMessage(null);
        }
    }, [location.pathname, mini]);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1199);
            setSmallMobile(window.innerWidth <= 576);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const resetMessageDisplay = () => {
        if (!mini) {
            const displayContainer = document.querySelector('.message-display');
            if (displayContainer) {
                displayContainer.style.position = 'static';
                displayContainer.innerHTML = `
                    <div class="pt-5 pb-5 d-flex justify-content-center align-content-center">
                        <h3 class="text-gray">Select a Message to View it Here</h3>
                    </div>
                `;
            }
        }
    };

        const getStaticMessages = () => {
            if (message_type === 'messages') {
                return [
                    {
                        id: 1,
                        mark_as_read: false,
                        title: "Unread S/P Title",
                        submit_date: "2024-09-04 15:25:17",
                        tags: [
                            {id: 1, title: "Service", color: "green"},
                            {id: 2, title: "Sales", color: "yellow"},
                            {id: 3, title: "Tag Here", color: "blue"},
                            {id: 4, title: "Another Tag", color: "green"},
                            {id: 5, title: "Last Tag", color: "yellow"}
                        ],
                        lock_message: true,
                        attachments: ['Attachment1', 'Attachment2'],
                        pinned: false,
                        content: [
                            {
                                "type": "image",
                                "src": "https://placehold.co/500x500",
                                "alt": "Test Image One"
                            },
                            {
                                "type": "h1",
                                "content": "Title Goes Here"
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. <a href='#'>Sed vitae lacus neque.</a> Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. Sed vitae lacus neque. Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "subtitle",
                                "content": "Subtitle Goes Here"
                            },
                            {
                                "type": "bullet-list",
                                "bullets": [
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this."
                                ]
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "subtitle",
                                "content": "Subtitle Goes Here"
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. Sed vitae lacus neque. Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "button",
                                "content": "Custom Link Here",
                                "href": "#",
                                "target": "_blank"
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "attachments",
                                "files": [
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "pdf",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "text",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "svg",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "csv",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "power-point",
                                        "file_id": 123
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        id: 2,
                        mark_as_read: true,
                        title: "Read/Pinned S/P Title",
                        submit_date: "2024-09-04 15:25:17",
                        tags: [
                            {id: 1, title: "Service", color: "green"},
                            {id: 3, title: "Tag Here", color: "blue"}
                        ],
                        lock_message: false,
                        attachments: [],
                        pinned: true,
                        content: [
                            {
                                "type": "image",
                                "src": "https://placehold.co/500x500",
                                "alt": "Test Image One"
                            },
                            {
                                "type": "h1",
                                "content": "Title Goes Here"
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. <a href='#'>Sed vitae lacus neque.</a> Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. Sed vitae lacus neque. Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "subtitle",
                                "content": "Subtitle Goes Here"
                            },
                            {
                                "type": "bullet-list",
                                "bullets": [
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this."
                                ]
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "subtitle",
                                "content": "Subtitle Goes Here"
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. Sed vitae lacus neque. Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "button",
                                "content": "Custom Link Here",
                                "href": "#",
                                "target": "_blank"
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "attachments",
                                "files": [
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "pdf",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "text",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "svg",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "csv",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "power-point",
                                        "file_id": 123
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        id: 3,
                        mark_as_read: true,
                        title: "Read Warranty Title",
                        submit_date: "2024-09-04 15:25:17",
                        tags: [{id: 1, title: "Service", color: "green"}],
                        lock_message: false,
                        attachments: [],
                        pinned: false,
                        content: [
                            {
                                "type": "image",
                                "src": "https://placehold.co/500x500",
                                "alt": "Test Image One"
                            },
                            {
                                "type": "h1",
                                "content": "Title Goes Here"
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. <a href='#'>Sed vitae lacus neque.</a> Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. Sed vitae lacus neque. Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "subtitle",
                                "content": "Subtitle Goes Here"
                            },
                            {
                                "type": "bullet-list",
                                "bullets": [
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this."
                                ]
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "subtitle",
                                "content": "Subtitle Goes Here"
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. Sed vitae lacus neque. Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "button",
                                "content": "Custom Link Here",
                                "href": "#",
                                "target": "_blank"
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "attachments",
                                "files": [
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "pdf",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "text",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "svg",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "csv",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "power-point",
                                        "file_id": 123
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        id: 4,
                        mark_as_read: false,
                        title: "Unread S/P Title",
                        submit_date: "2024-09-04 15:25:17",
                        tags: [
                            {id: 1, title: "Service", color: "green"},
                            {id: 2, title: "Sales", color: "yellow"},
                            {id: 3, title: "Tag Here", color: "blue"},
                            {id: 4, title: "Another Tag", color: "green"},
                            {id: 5, title: "Last Tag", color: "yellow"}
                        ],
                        lock_message: false,
                        attachments: ['Attachment1', 'Attachment2'],
                        pinned: false,
                        content: [
                            {
                                "type": "image",
                                "src": "https://placehold.co/500x500",
                                "alt": "Test Image One"
                            },
                            {
                                "type": "h1",
                                "content": "Title Goes Here"
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. <a href='#'>Sed vitae lacus neque.</a> Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. Sed vitae lacus neque. Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "subtitle",
                                "content": "Subtitle Goes Here"
                            },
                            {
                                "type": "bullet-list",
                                "bullets": [
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this."
                                ]
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "subtitle",
                                "content": "Subtitle Goes Here"
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. Sed vitae lacus neque. Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "button",
                                "content": "Custom Link Here",
                                "href": "#",
                                "target": "_blank"
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "attachments",
                                "files": [
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "pdf",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "text",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "svg",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "csv",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "power-point",
                                        "file_id": 123
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        id: 5,
                        mark_as_read: true,
                        title: "Read/Pinned S/P Title",
                        submit_date: "2024-09-04 15:25:17",
                        tags: [
                            {id: 1, title: "Service", color: "green"},
                            {id: 3, title: "Tag Here", color: "blue"}
                        ],
                        lock_message: false,
                        attachments: [],
                        pinned: true,
                        content: [
                            {
                                "type": "image",
                                "src": "https://placehold.co/500x500",
                                "alt": "Test Image One"
                            },
                            {
                                "type": "h1",
                                "content": "Title Goes Here"
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. <a href='#'>Sed vitae lacus neque.</a> Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. Sed vitae lacus neque. Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "subtitle",
                                "content": "Subtitle Goes Here"
                            },
                            {
                                "type": "bullet-list",
                                "bullets": [
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this."
                                ]
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "subtitle",
                                "content": "Subtitle Goes Here"
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. Sed vitae lacus neque. Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "button",
                                "content": "Custom Link Here",
                                "href": "#",
                                "target": "_blank"
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "attachments",
                                "files": [
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "pdf",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "text",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "svg",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "csv",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "power-point",
                                        "file_id": 123
                                    }
                                ]
                            }
                        ]
                    }
                ];
            } else if (message_type === 'drafts') {
                return [
                    {
                        id: 1,
                        title: "Title of Draft",
                        draft_date: "2024-09-04 15:25:17",
                        tags: [
                            {id: 1, title: "Service", color: "green"},
                            {id: 2, title: "Sales", color: "yellow"},
                            {id: 3, title: "Tag Here", color: "blue"},
                            {id: 4, title: "Another Tag", color: "green"},
                            {id: 5, title: "Last Tag", color: "yellow"}
                        ],
                        attachments: ['Attachment1', 'Attachment2'],
                        content: [
                            {
                                "type": "image",
                                "src": "https://placehold.co/500x500",
                                "alt": "Test Image One"
                            },
                            {
                                "type": "h1",
                                "content": "Title Goes Here"
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. <a href='#'>Sed vitae lacus neque.</a> Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. Sed vitae lacus neque. Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "subtitle",
                                "content": "Subtitle Goes Here"
                            },
                            {
                                "type": "bullet-list",
                                "bullets": [
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this."
                                ]
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "subtitle",
                                "content": "Subtitle Goes Here"
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. Sed vitae lacus neque. Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "button",
                                "content": "Custom Link Here",
                                "href": "#",
                                "target": "_blank"
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "attachments",
                                "files": [
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "pdf",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "text",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "svg",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "csv",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "power-point",
                                        "file_id": 123
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        id: 2,
                        title: "Title of Draft",
                        draft_date: "2024-09-04 15:25:17",
                        tags: [],
                        attachments: ['Attachment1', 'Attachment2'],
                        content: [
                            {
                                "type": "image",
                                "src": "https://placehold.co/500x500",
                                "alt": "Test Image One"
                            },
                            {
                                "type": "h1",
                                "content": "Title Goes Here"
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. <a href='#'>Sed vitae lacus neque.</a> Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. Sed vitae lacus neque. Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "subtitle",
                                "content": "Subtitle Goes Here"
                            },
                            {
                                "type": "bullet-list",
                                "bullets": [
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this."
                                ]
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "subtitle",
                                "content": "Subtitle Goes Here"
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. Sed vitae lacus neque. Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "button",
                                "content": "Custom Link Here",
                                "href": "#",
                                "target": "_blank"
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "attachments",
                                "files": [
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "pdf",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "text",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "svg",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "csv",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "power-point",
                                        "file_id": 123
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        id: 3,
                        title: "Title of Draft",
                        draft_date: "2024-09-04 15:25:17",
                        tags: [
                            {id: 1, title: "Service", color: "green"},
                            {id: 2, title: "Sales", color: "yellow"}
                        ],
                        attachments: ['Attachment1', 'Attachment2'],
                        content: [
                            {
                                "type": "image",
                                "src": "https://placehold.co/500x500",
                                "alt": "Test Image One"
                            },
                            {
                                "type": "h1",
                                "content": "Title Goes Here"
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. <a href='#'>Sed vitae lacus neque.</a> Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. Sed vitae lacus neque. Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "subtitle",
                                "content": "Subtitle Goes Here"
                            },
                            {
                                "type": "bullet-list",
                                "bullets": [
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this."
                                ]
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "subtitle",
                                "content": "Subtitle Goes Here"
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. Sed vitae lacus neque. Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "button",
                                "content": "Custom Link Here",
                                "href": "#",
                                "target": "_blank"
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "attachments",
                                "files": [
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "pdf",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "text",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "svg",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "csv",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "power-point",
                                        "file_id": 123
                                    }
                                ]
                            }
                        ]
                    }
                    // Add other messages as needed
                ];
            } else if (message_type === 'pending') {
                return [
                    {
                        id: 1,
                        title: "Title of Approved Draft",
                        draft_date: "2024-09-04 15:25:17",
                        tags: [
                            {id: 1, title: "Service", color: "green"},
                            {id: 2, title: "Sales", color: "yellow"},
                            {id: 3, title: "Tag Here", color: "blue"},
                            {id: 4, title: "Another Tag", color: "green"},
                            {id: 5, title: "Last Tag", color: "yellow"}
                        ],
                        status: 'approved',
                        attachments: ['Attachment1', 'Attachment2'],
                        content: [
                            {
                                "type": "image",
                                "src": "https://placehold.co/500x500",
                                "alt": "Test Image One"
                            },
                            {
                                "type": "h1",
                                "content": "Title Goes Here"
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. <a href='#'>Sed vitae lacus neque.</a> Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. Sed vitae lacus neque. Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "subtitle",
                                "content": "Subtitle Goes Here"
                            },
                            {
                                "type": "bullet-list",
                                "bullets": [
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this."
                                ]
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "subtitle",
                                "content": "Subtitle Goes Here"
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. Sed vitae lacus neque. Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "button",
                                "content": "Custom Link Here",
                                "href": "#",
                                "target": "_blank"
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "attachments",
                                "files": [
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "pdf",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "text",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "svg",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "csv",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "power-point",
                                        "file_id": 123
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        id: 2,
                        title: "Title of Denied Draft",
                        draft_date: "2024-09-04 15:25:17",
                        tags: [],
                        status: 'denied',
                        attachments: ['Attachment1'],
                        content: [
                            {
                                "type": "image",
                                "src": "https://placehold.co/500x500",
                                "alt": "Test Image One"
                            },
                            {
                                "type": "h1",
                                "content": "Title Goes Here"
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. <a href='#'>Sed vitae lacus neque.</a> Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. Sed vitae lacus neque. Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "subtitle",
                                "content": "Subtitle Goes Here"
                            },
                            {
                                "type": "bullet-list",
                                "bullets": [
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this."
                                ]
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "subtitle",
                                "content": "Subtitle Goes Here"
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. Sed vitae lacus neque. Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "button",
                                "content": "Custom Link Here",
                                "href": "#",
                                "target": "_blank"
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "attachments",
                                "files": [
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "pdf",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "text",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "svg",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "csv",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "power-point",
                                        "file_id": 123
                                    }
                                ]
                            }
                        ]
                    },
                    {
                        id: 3,
                        title: "Title of Pending Draft",
                        draft_date: "2024-09-04 15:25:17",
                        status: 'pending_approval',
                        tags: [
                            {id: 1, title: "Service", color: "green"},
                            {id: 2, title: "Sales", color: "yellow"}
                        ],
                        attachments: ['Attachment1', 'Attachment2'],
                        content: [
                            {
                                "type": "image",
                                "src": "https://placehold.co/500x500",
                                "alt": "Test Image One"
                            },
                            {
                                "type": "h1",
                                "content": "Title Goes Here"
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. <a href='#'>Sed vitae lacus neque.</a> Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. Sed vitae lacus neque. Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "subtitle",
                                "content": "Subtitle Goes Here"
                            },
                            {
                                "type": "bullet-list",
                                "bullets": [
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this.",
                                    "A bullet point would go here and look like this."
                                ]
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "subtitle",
                                "content": "Subtitle Goes Here"
                            },
                            {
                                "type": "text",
                                "content": "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ac orci vel eros malesuada ultricies. Sed vitae lacus neque. Nam sit amet orci euismod, tincidunt dolor vel, dignissim orci. Aliquam erat volutpat. Maecenas cursus metus ac justo bibendum, a facilisis nunc blandit. Integer nec lacus auctor, cursus turpis et, accumsan ligula. Donec at felis in urna elementum tristique nec vel ipsum. Fusce auctor eros in lorem aliquet, sed feugiat magna convallis. Sed sit amet erat orci. Vivamus vitae dui nec sapien convallis scelerisque a in odio. Curabitur feugiat justo nec lectus tempus, sit amet aliquam odio aliquam. Nullam eget felis in elit dictum consectetur. Sed nec libero sed leo consequat vehicula non a enim."
                            },
                            {
                                "type": "button",
                                "content": "Custom Link Here",
                                "href": "#",
                                "target": "_blank"
                            },
                            {
                                "type": "hr"
                            },
                            {
                                "type": "attachments",
                                "files": [
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "pdf",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "text",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "svg",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "csv",
                                        "file_id": 123
                                    },
                                    {
                                        "name": "Title of Document Goes Here",
                                        "size": "1.2MB",
                                        "type": "power-point",
                                        "file_id": 123
                                    }
                                ]
                            }
                        ]
                    }
                    // Add other messages as needed
                ];
            }
        }


        const handleSelectMessage = (message) => {
            setSelectedMessageId(message.id);
            setSelectedMessage(message);

            if (isMobile || mini) {
                setShowModal(true);
            } else {
                setDisplayContent(openMessage(message, message_type));
            }
        };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedMessage(null);
    };

    const handlePinToggle = (messageId) => {
        setMessages(prevMessages => prevMessages.map(msg =>
            msg.id === messageId ? {...msg, pinned: !msg.pinned} : msg
        ));
    };

    const renderMessageHeader = () => {
        if (!selectedMessage) return null;

        switch (message_type) {
            case 'messages':
                return (
                    <div className="d-flex justify-content-center align-items-center">
                        <h3 className="me-4 mt-2">Mark as Read?</h3>
                        <Button color="red" text="YES" />
                    </div>
                );
            case 'drafts':
                return (
                    <div className="d-flex justify-content-center align-items-center">
                        <h3 className="me-4 mt-2">Submit for Approval?</h3>
                        <Button color="red" text="SUBMIT" />
                    </div>
                );
            case 'pending':
                switch (selectedMessage.status) {
                    case 'pending_approval':
                        return (
                            <div className="d-flex justify-content-center align-items-center">
                                <h3 className="me-4 mt-2">Mark approved?</h3>
                                <Button color="blue" text="YES" className="me-2" />
                                <Button color="red" text="NO" className="me-3" />
                                <h3 className="mt-2">3/5 Approved</h3>
                            </div>
                        );
                    case 'approved':
                        return (
                            <div className="d-flex justify-content-center align-items-center">
                                <Button color="red" text="SEND NOW" className="me-4" />
                                <h3 className="mt-3 me-4">-OR-</h3>
                                <input className="input-main me-3" type="date" id="schedule_date" name="schedule_date" />
                                <input className="input-main me-4" type="time" id="schedule_time" name="schedule_time" />
                                <Button color="red" text="SCHEDULE" className="me-2" />
                            </div>
                        );
                    default:
                        return (
                            <div className="d-flex justify-content-center align-items-center">
                                <Button color="red" text="SEND TO DRAFTS" className="me-3" />
                                <h3 className="me-4 mt-2">-OR-</h3>
                                <Button color="red" text="DELETE" className="me-2" />
                            </div>
                        );
                }
            default:
                return null;
        }
    };

    const renderMessages = (filterType = 'all') => {
        let filteredMessages = messages;
        if (filterType === 'read') {
            filteredMessages = messages.filter(msg => msg.mark_as_read);
        } else if (filterType === 'unread') {
            filteredMessages = messages.filter(msg => !msg.mark_as_read);
        }

        const renderMessageGroup = (title, messageGroup) => (
            <div key={title}>
                <h3>{title}</h3>
                {messageGroup.length > 0 ? messageGroup.map(message => (
                    <Message
                        key={message.id}
                        type={message_type}
                        message={message}
                        isSelected={selectedMessageId === message.id}
                        onSelect={() => handleSelectMessage(message)}
                        onPinToggle={handlePinToggle}
                    />
                )) : <div>No {title.toLowerCase()} messages</div>}
                <PageDividerHr />
            </div>
        );

        if (message_type === 'messages' && !mini) {
            const lockedMessages = filteredMessages.filter(message => message.lock_message);
            const pinnedMessages = filteredMessages.filter(message => message.pinned && !message.lock_message);
            const otherMessages = filteredMessages.filter(message => !message.lock_message && !message.pinned);

            return (
                <>
                    {renderMessageGroup('Locked', lockedMessages)}
                    {renderMessageGroup('Pinned', pinnedMessages)}
                    {renderMessageGroup('Other', otherMessages)}
                </>
            );
        } else if (message_type === 'pending' && !mini) {
            const approvedDrafts = filteredMessages.filter(message => message.status === 'approved');
            const deniedDrafts = filteredMessages.filter(message => message.status === 'denied');
            const pendingApproval = filteredMessages.filter(message => message.status === 'pending_approval');

            return (
                <>
                    {renderMessageGroup('Approved', approvedDrafts)}
                    {renderMessageGroup('Denied', deniedDrafts)}
                    {renderMessageGroup('Pending', pendingApproval)}
                </>
            );
        } else {
            return filteredMessages.map(message => (
                <Message
                    key={message.id}
                    type={message_type}
                    message={message}
                    isSelected={selectedMessageId === message.id}
                    onSelect={() => handleSelectMessage(message)}
                    onPinToggle={handlePinToggle}
                />
            ));
        }
    };

    const renderHeader = () => (
        <div className="row align-items-center mb-3">
            <div className="col-xl-9 col-lg-12 d-flex justify-content-start">

                <div className={"me-2"}>
                    <Button color="red" text="Create"/>
                </div>

                <div className={"col-xl-2 me-3"}>
                    <select className={"form-select input-alt"}>
                        <option>A-Z</option>
                        <option>Z-A</option>
                    </select>
                </div>

                <div className={"col-xl-2 me-3"}>
                    <select className={"form-select input-alt"}>
                        <option>Sales & Service</option>
                        <option>Sales</option>
                        <option>Service</option>
                    </select>
                </div>

                {['messages', 'pending'].includes(message_type) && (
                    <div className={"col-xl-1 me-3"}>
                        <select className={"form-select input-alt"}>
                            <option>All</option>
                            <option>Locked</option>
                            <option>Pinned</option>
                        </select>
                    </div>
                )}

                {message_type === 'messages' && (
                    <div className={"col-xl-1 me-3"}>
                        <select className={"form-select input-alt"}>
                            <option>Active</option>
                            <option>Read</option>
                            <option>Unread</option>
                        </select>
                    </div>
                )}
                    <div className="col-xl-3">
                        <div className="input-group">
                            <input type="text" className="form-control input-alt" name="searchFiles" id="searchFiles"
                                   value="Search"/>
                            <span className="input-group-text input-alt">
                                <i className="fas fa-search"></i>
                            </span>
                        </div>
                    </div>
                </div>

            <div className="col-xl-3 col-lg-12 d-flex justify-content-end">
                <nav aria-label="Page navigation">
                    <ul className="pagination custom-pagination">
                        {[1, 2, 3, 4].map(page => (
                            <li key={page} className={`page-item ${page === 3 ? 'active' : ''}`}>
                                <a className="page-link" href="/contact/list/">{page}</a>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </div>
    );

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (mini) {
        return (
            <div>
                <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="messages-all" role="tabpanel" aria-labelledby="nav-messages-all">
                        {renderMessages('all')}
                    </div>
                    <div className="tab-pane fade" id="messages-read" role="tabpanel" aria-labelledby="nav-messages-read">
                        {renderMessages('read')}
                    </div>
                    <div className="tab-pane fade" id="messages-unread" role="tabpanel" aria-labelledby="nav-messages-unread">
                        {renderMessages('unread')}
                    </div>
                </div>
                {showModal && selectedMessage && (
                    <Modal show={showModal} onClose={handleCloseModal} title={selectedMessage.title}>
                        {renderMessageHeader()}
                        <PageDividerHr />
                        {renderMessageContent(selectedMessage.content)}
                    </Modal>
                )}
            </div>
        );
    }

    return (
        <div className="message-list-container d-flex flex-column min-vh-100">
            <PageTitle title="Inbox" />
            {renderHeader()}
            <PageDividerHr />
            <div className="row mt-2">
                <div className="col-12 col-xl-5 message-list">
                    {renderMessages()}
                </div>
                <div className="col-xl-7 message-display-container d-none d-xl-block custom-hide-lg">
                    <Card className="pt-5 pb-5 message-display d-block">
                        {displayContent ? displayContent : (
                            <div className="pt-5 pb-5 d-flex justify-content-center align-content-center">
                                <h3 className="text-gray">Select a Message to View it Here</h3>
                            </div>
                        )}
                    </Card>
                </div>
            </div>
            <div className="mt-auto">
                <PageDividerHr />
                <div className="row align-items-center mb-3">
                    <div className="col-sm-9 d-flex justify-content-start">
                        <Button color="red" text="Create" className="me-2" />
                    </div>
                    <div className="col-sm-3 d-flex justify-content-end" />
                </div>
            </div>
            {showModal && selectedMessage && (
                <Modal show={showModal} onClose={handleCloseModal} title={selectedMessage.title}>
                    {renderMessageHeader()}
                    <PageDividerHr />
                    {renderMessageContent(selectedMessage.content)}
                </Modal>
            )}
        </div>
    );
};

export default MessageList;
import React from 'react';

const Button = ({ text, color, link, onClick, noMargin, disabled }) => {
    // Conditionally apply the `me-2` class if noMargin is false
    const marginClass = noMargin ? '' : 'me-2';
    const baseClass = `btn btn-${color} ${marginClass}`;

    const handleClick = (e) => {
        if (onClick) {
            onClick(e);
        }
    };

    if (link) {
        return (
            <a className={baseClass} href={link}>
                {text}
            </a>
        );
    } else {
        return (
            <button
                className={baseClass}
                onClick={handleClick}
                disabled={disabled}
            >
                {text}
            </button>
        );
    }
};

export default Button;
import React, { useState, useEffect, useRef } from 'react';
import { Collapse } from 'bootstrap';

const FolderItem = ({ item, level = 0, onSelect }) => {
    const [isOpen, setIsOpen] = useState(false);
    const hasChildren = item.children && item.children.length > 0;

    const handleClick = () => {
        if (hasChildren) {
            toggleFolder();
        } else {
            onSelect(item);
        }
    };

    const toggleFolder = () => {
        if (hasChildren){
            setIsOpen(!isOpen);
        }
    };

    const headerClass = hasChildren
        ? isOpen
            ? 'folder-header-open'
            : 'folder-header-closed'
        : 'folder-header-closed';

    const getFolderIcon = () => {
        if (item.icon === 'fa-folder') {
            return isOpen ? 'fa-folder-open' : 'fa-folder';
        }
        return item.icon;
    };

    const underLineClass = item.icon === '' ? 'underline' : 'folder-dark-title';

    return (
        <div className={`folder-item ${level > 0 ? 'subfolder-item' : ''}`}>
            <div
                className={`folder-header ${headerClass}`}
                onClick={handleClick}
            >
                <div className="folder-header-content">
                    <div className="folder-header-left">
                        {item.icon !== '' && (
                            <i className={`fas ${getFolderIcon()} folder-icon fa-lg`}></i>
                        )}
                        <span className={`folder-title ${underLineClass}`}>{item.title}</span>
                    </div>
                    {item.icon === '' && (
                        <div className="folder-header-right">
                            <span className="folder-items">{Number(item.contents).toLocaleString('en-US')}</span>
                            <span className="folder-date">{item.date}</span>
                            <i className={"fas fa-ellipsis-vertical text-blue"}></i>
                        </div>
                    )}
                </div>
                {hasChildren && (
                    <i className={`fa-light fa-chevron-down chevron-icon ${isOpen ? 'rotate' : ''}`}></i>
                )}
            </div>
            {hasChildren && isOpen && (
                <div className="folder-content">
                    {item.children.map((child) => (
                        <FolderItem key={child.id} item={child} level={level + 1} onSelect={onSelect} />
                    ))}
                </div>
            )}
        </div>
    );
};

const FolderAccordion = ({ data, onSelect }) => {
    return (
        <div className="folder-accordion">
            {data.map((item) => (
                <FolderItem key={item.id} item={item} onSelect={onSelect} />
            ))}
        </div>
    );
};

export default FolderAccordion;
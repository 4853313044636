import axios from 'axios';

const uploadAttachments = async (files) => {
    console.log("Starting uploadAttachments with files:", files);
    const uploadedFiles = [];

    for (const file of files) {
        try {
            console.log("Processing file:", file.name, "Size:", file.size, "Type:", file.type);

            const response = await axios.get('https://toroportal.volanosoftware.com/api/v1/Messages/file-url', {
                params: { blobName: file.name }
            });

            console.log("API response:", response.data);

            if (!response.data || !response.data.presignedUrl) {
                throw new Error("Invalid response from API. Expected a presignedUrl.");
            }

            const signedUrl = response.data.presignedUrl;
            console.log("Received signed URL:", signedUrl);

            // Step 2: Upload the file to Azure Blob Storage
            const currentDate = new Date().toUTCString();
            console.log("Uploading file to Azure Blob Storage...");

            if (!(file instanceof Blob)) {
                throw new Error("Invalid file object. Expected a Blob or File object.");
            }

            const uploadResponse = await axios.put(signedUrl, file, {
                headers: {
                    'x-ms-version': '2015-02-21',
                    'x-ms-date': currentDate,
                    'Content-Type': file.type,
                    'x-ms-blob-type': 'BlockBlob'
                },
                maxBodyLength: Infinity,
                maxContentLength: Infinity
            });

            console.log("File upload response:", uploadResponse);

            if (uploadResponse.status === 201) {
                console.log("File uploaded successfully");
                const fileUrl = signedUrl.split('?')[0];
                console.log("File URL:", fileUrl);
                uploadedFiles.push({ name: file.name, url: fileUrl });
            } else {
                throw new Error(`File upload failed. Status: ${uploadResponse.status}`);
            }
        } catch (error) {
            console.error(`Error processing file ${file.name}:`, error);
            if (error.message.includes('Network Error') || error.message.includes('CORS')) {
                console.error("CORS error detected. Please ensure CORS is properly configured on your Azure Blob Storage account.");
            }
            console.error("Full error object:", JSON.stringify(error, Object.getOwnPropertyNames(error)));
            if (error.response) {
                console.error("Response data:", error.response.data);
                console.error("Response status:", error.response.status);
                console.error("Response headers:", error.response.headers);
            } else if (error.request) {
                console.error("No response received:", error.request);
            } else {
                console.error("Error message:", error.message);
            }
            if (error.config) {
                console.error("Request config:", JSON.stringify(error.config, null, 2));
            }
        }
    }

    console.log("Finished uploadAttachments. Uploaded files:", uploadedFiles);
    return uploadedFiles;
};

export default uploadAttachments;
// Just the output of the header of each page
import React from 'react';

function PageTitle({ title }) {
    return (
        <div className="row">
            <div className="col-12 mb-4">
                <span className={"h3"}>{title}</span>
            </div>
        </div>
    );
}

export default PageTitle;
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './components/template/Sidebar';
import Navbar from './components/template/Navbar';
import Dashboard from './components/pages/Dashboard';
import MessageCreateForm from './components/pages/MessageCreateForm';
import ContactListHome from './components/pages/ContactListHome';
import Footer from './components/template/Footer';
import FAQ from './components/pages/FAQ';
import Test from './components/pages/Test';
import MessageList from './components/pages/MessageList';
import MediaListHome from './components/pages/MediaListHome';
import FeatureRequest from './components/pages/FeatureRequest';
import Support from './components/pages/Support';
import Contact from './components/pages/Contact';
import DefaultPage from './components/pages/DefaultPage';

import 'bootstrap/dist/js/bootstrap.bundle.min';

import './css/dark.css';
import './css/custom.css';
import './css/main.css';
import { initializeSidebarToggle } from './js/app.js';
import ContactCreateForm from "./components/pages/ContactCreateForm";
import HelpDocs from "./components/pages/HelpDocs";

function App() {
    useEffect(() => {
        initializeSidebarToggle();  // Call the function when the component mounts
    }, []);
    return (
        <Router>
        <div className="wrapper">
            <Sidebar />
            <div className="main">
                <Navbar/>
                <main className="content">
                    <div className="container-fluid p-0">
                        <Routes>
                            <Route path="/" element={<Dashboard/>}/>
                            <Route path="messages/create/" element={<MessageCreateForm/>}/>
                            <Route path="messages/list/" element={<MessageList message_type="messages" mini={false} />} />
                            <Route path="drafts/list/" element={<MessageList message_type="drafts" mini={false} />} />
                            <Route path="pending/list/" element={<MessageList message_type="pending" mini={false} />} />
                            <Route path="contact/list/" element={<ContactListHome/>}/>
                            <Route path="contact/create/" element={<ContactCreateForm/>}/>
                            <Route path="media/list/" element={<MediaListHome/>}/>
                            <Route path="faqs/" element={<FAQ/>}/>
                            <Route path="help-docs/" element={<HelpDocs/>}/>
                            <Route path="feature-request/" element={<FeatureRequest/>}/>
                            <Route path="test/" element={<Test/>}/>
                            <Route path="contact/" element={<Contact/>}/>
                            <Route path="support/" element={<Support/>}/>


                            <Route path="unit-registrations/" element={<DefaultPage/>}/>
                            <Route path="toro-total-care/" element={<DefaultPage/>}/>
                            <Route path="toro-total-care-after/" element={<DefaultPage/>}/>
                            <Route path="bid-fleet-claim/" element={<DefaultPage/>}/>
                            <Route path="marketing-toolkit/" element={<DefaultPage/>}/>
                            <Route path="pricing-info/" element={<DefaultPage/>}/>
                            <Route path="map/" element={<DefaultPage/>}/>
                            <Route path="programs/" element={<DefaultPage/>}/>
                            <Route path="promotions/" element={<DefaultPage/>}/>
                            <Route path="promotional-assets/" element={<DefaultPage/>}/>
                            <Route path="red-iron-financing/" element={<DefaultPage/>}/>
                            <Route path="sheffield/" element={<DefaultPage/>}/>
                            <Route path="meridian/" element={<DefaultPage/>}/>
                            <Route path="other-financing/" element={<DefaultPage/>}/>
                            <Route path="toro-total-care-parts/" element={<DefaultPage/>}/>
                            <Route path="titania/" element={<DefaultPage/>}/>
                            <Route path="docs-and-resources/" element={<DefaultPage/>}/>
                            <Route path="inventory/" element={<DefaultPage/>}/>
                            <Route path="digital-marketing/" element={<DefaultPage/>}/>
                            <Route path="leads/" element={<DefaultPage/>}/>
                            <Route path="print-direct-mail/" element={<DefaultPage/>}/>
                            <Route path="images-and-video/" element={<DefaultPage/>}/>
                            <Route path="print-assets/" element={<DefaultPage/>}/>
                            <Route path="pop-merchandising/" element={<DefaultPage/>}/>
                            <Route path="sales/" element={<DefaultPage/>}/>
                            <Route path="document-center/" element={<DefaultPage/>}/>
                            <Route path="toro-university/" element={<DefaultPage/>}/>
                            <Route path="partner-analytics/" element={<DefaultPage/>}/>
                            <Route path="red-iron-analytics/" element={<DefaultPage/>}/>
                            <Route path="messages/sent/" element={<DefaultPage/>}/>
                            <Route path="faq-claim/" element={<DefaultPage/>}/>
                            <Route path="faq-service/" element={<DefaultPage/>}/>
                            <Route path="message-create/" element={<DefaultPage/>}/>
                            <Route path="message-list/" element={<DefaultPage/>}/>
                            <Route path="message-sent/" element={<DefaultPage/>}/>
                            <Route path="drafts-list/" element={<DefaultPage/>}/>
                            <Route path="contact-list/" element={<DefaultPage/>}/>
                            <Route path="media-list/" element={<DefaultPage/>}/>
                            <Route path="settings-general/" element={<DefaultPage/>}/>
                            <Route path="settings-security/" element={<DefaultPage/>}/>
                            <Route path="pc-general/" element={<DefaultPage/>}/>
                            <Route path="pc-security/" element={<DefaultPage/>}/>
                            <Route path="dr-general/" element={<DefaultPage/>}/>
                            <Route path="dr-security/" element={<DefaultPage/>}/>
                            <Route path="users-create/" element={<DefaultPage/>}/>
                            <Route path="users-list/" element={<DefaultPage/>}/>

                        </Routes>
                    </div>
                </main>
                <Footer/>
            </div>
        </div>
        </Router>
    );
}

export default App;

import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import sidebarData from '../../settings_json/Sidebar.json';

function Sidebar() {
    const [openItems, setOpenItems] = useState([]);
    const location = useLocation();

    useEffect(() => {
        const currentItem = findItemByPath(sidebarData.items, location.pathname);
        if (currentItem && currentItem.parents) {
            setOpenItems(currentItem.parents);
        }
    }, [location]);

    const findItemByPath = (items, path, parents = []) => {
        for (let item of items) {
            if (item.link === path) {
                return { ...item, parents };
            }
            if (item.children) {
                const found = findItemByPath(item.children, path, [...parents, item.title]);
                if (found) return found;
            }
        }
        return null;
    };

    const toggleItem = (title) => {
        setOpenItems(prevOpenItems =>
            prevOpenItems.includes(title)
                ? prevOpenItems.filter(item => item !== title)
                : [...prevOpenItems, title]
        );
    };

    const renderSidebarHeader = (item, index) => (
        <li key={`header-${item.title}-${index}`} className="sidebar-header">
            {item.title}
        </li>
    );

    const renderSidebarItem = (item, index, depth = 0) => {
        const isOpen = openItems.includes(item.title);
        const hasChildren = item.children && item.children.length > 0;
        // Change 1: Modified isActive to check for exact match
        const isActive = item.link === location.pathname;
        // Change 2: Added isParentOfActive check
        const isParentOfActive = hasChildren && item.children.some(child =>
            child.link === location.pathname ||
            (child.children && child.children.some(grandchild => grandchild.link === location.pathname))
        );

        return (
            // Change 3: Updated className to include both active and parent-active
            <li key={`item-${item.title}-${index}-${depth}`} className={`sidebar-item ${isActive ? 'active' : ''} ${isParentOfActive ? 'parent-active' : ''}`}>
                <a
                    href={item.link || "#"}
                    onClick={(e) => {
                        if (hasChildren) {
                            e.preventDefault();
                            toggleItem(item.title);
                        }
                    }}
                    data-bs-toggle={hasChildren ? "collapse" : null}
                    // Change 4: Added active class to the <a> element for exact match
                    className={`sidebar-link ${hasChildren ? (isOpen ? '' : 'collapsed') : ''} ${isActive ? 'active' : ''}`}
                    aria-expanded={hasChildren ? (isOpen ? "true" : "false") : null}
                    style={{ paddingLeft: `${20 + depth * 15}px` }}
                >
                    {item.icon && <i className={`align-middle ${item.icon}`}></i>}
                    <span className="align-middle">{item.title}</span>
                </a>
                {hasChildren && (
                    <ul
                        id={item.title.replace(/\s+/g, '')}
                        className={`sidebar-dropdown list-unstyled collapse ${isOpen ? 'show' : ''}`}
                        data-bs-parent="#sidebar"
                    >
                        {item.children.map((child, childIndex) =>
                            renderSidebarItem(child, childIndex, depth + 1)
                        )}
                    </ul>
                )}
            </li>
        );
    };

    const renderSidebarContent = (item, index) => {
        switch (item.type) {
            case 'divider-pt':
                return <li key={`divider-pt-${index}`} className="sidebar-item sidebar-divider-padded-top align-middle"></li>;
            case 'divider-pb':
                return <li key={`divider-pb-${index}`} className="sidebar-item sidebar-divider-padded-bottom align-middle"></li>;
            case 'header':
                return renderSidebarHeader(item, index);
            case 'item':
            default:
                return renderSidebarItem(item, index);
        }
    };

    return (
        <nav id="sidebar" className="sidebar">
            <div className="sidebar-content js-simplebar">
                <Link className="sidebar-brand" to="/">
                    <img src={sidebarData.logo.src} alt={sidebarData.logo.alt} className="img-responsive" style={{maxWidth: '100%'}}/>
                </Link>
                <ul className="sidebar-nav">
                    {sidebarData.items.map((item, index) => renderSidebarContent(item, index))}
                </ul>
            </div>
        </nav>
    );
}

export default Sidebar;
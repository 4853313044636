import React, { useState, useEffect, useRef } from 'react';

const FormDropdown = ({ options, title, type }) => {
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleOptionToggle = (value) => {
        setSelectedOptions((prevSelected) =>
            prevSelected.includes(value)
                ? prevSelected.filter((item) => item !== value)
                : [...prevSelected, value]
        );
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const dropdownClassName = `btn dropdown-toggle input-${type || 'main'} w-100 text-start d-flex justify-content-between align-items-center`;

    return (
        <div className="dropdown" ref={dropdownRef}>
            <button
                className={dropdownClassName}
                type="button"
                onClick={toggleDropdown}
                aria-expanded={isOpen}
            >
                <span>{title}</span>
                <span className="dropdown-toggle-icon"></span>
            </button>
            {isOpen && (
                <ul className="dropdown-menu custom-dropdown-menu w-100 show">
                    {options.map((option, index) => (
                        <li key={index}>
                            <div
                                className="dropdown-item d-flex align-items-center"
                                onClick={() => handleOptionToggle(option.value)}
                            >
                                <input
                                    type="checkbox"
                                    className="form-check-input me-2"
                                    checked={selectedOptions.includes(option.value)}
                                    readOnly
                                />
                                <span>{option.label}</span>
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default FormDropdown;
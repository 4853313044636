import React, { useState, useEffect } from 'react';
import PageTitle from "../elements/PageTitle";
import Button from "../elements/Button";
import Card from "../elements/Card";
import PageDividerHr from "../elements/PageDividerHr";
import FolderAccordion from "../elements/FolderAccordion";
import Table from "../elements/Table";
import Modal from "../elements/Modal";

const ContactListHome = () => {
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1199);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1199);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleFolderSelect = (folder) => {
        setSelectedFolder(folder);
        if (isMobile) {
            setShowModal(true);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedFolder(null);
    };

    const listSortOptions = [
        { value: 'az', label: 'A-Z' }
    ];

    const folderData = [
        {
            id: 'custom_folder_name',
            title: 'Custom Folder Name',
            icon: 'fa-folder',
            children: [
                {
                    id: 'brand-assets',
                    title: 'Brand Assets',
                    icon: 'fa-folder',
                    children: [
                        {
                            id: 'logo-files',
                            title: 'Logo Files',
                            icon: 'fa-file'
                        },
                        {
                            id: 'subfolder',
                            title: 'Subfolder Here',
                            icon: 'fa-folder',
                            children: []
                        }
                    ]
                },
                {
                    id: 'dealer-assets',
                    title: 'Dealer Assets',
                    icon: 'fa-file'
                },
                {
                    id: 'digital-assets',
                    title: 'Digital Assets',
                    icon: 'fa-file'
                }
            ]
        },
        {
            id: 'intimidator_utv',
            title: 'Intimidator UTV',
            icon: 'fa-folder',
            children: []
        },
        {
            id: 'system_lists',
            title: 'System Lists',
            icon: 'fa-folder',
            children: [
                {
                    id: 'all_contacts',
                    title: 'All Contacts',
                    icon: '',
                    date: '06/13/2024',
                    last_updated: '06/13/2024 @ 5:45PM',
                    contents: 10356,
                    children: [],
                    data: [
                        {
                            first_name: 'John',
                            last_name: 'Doe',
                            email: 'fakeemail123@gmail.com',
                            phone: '000.000.0000',
                            company: 'VisionAmp Web Design'
                        },
                        {
                            first_name: 'John',
                            last_name: 'Doe',
                            email: 'fakeemail123@gmail.com',
                            phone: '000.000.0000',
                            company: 'VisionAmp Web Design'
                        },
                        {
                            first_name: 'John',
                            last_name: 'Doe',
                            email: 'fakeemail123@gmail.com',
                            phone: '000.000.0000',
                            company: 'VisionAmp Web Design'
                        },
                        {
                            first_name: 'John',
                            last_name: 'Doe',
                            email: 'fakeemail123@gmail.com',
                            phone: '000.000.0000',
                            company: 'VisionAmp Web Design'
                        },
                        {
                            first_name: 'John',
                            last_name: 'Doe',
                            email: 'fakeemail123@gmail.com',
                            phone: '000.000.0000',
                            company: 'VisionAmp Web Design'
                        }
                    ]
                },
                {
                    id: 'list_name_goes_here',
                    title: 'List Name Goes Here',
                    icon: '',
                    date: '06/13/2024',
                    last_updated: '06/13/2024 @ 5:45PM',
                    contents: 256,
                    children: [],
                    data: [
                        {
                            first_name: 'John',
                            last_name: 'Doe',
                            email: 'fakeemail123@gmail.com',
                            phone: '000.000.0000',
                            company: 'VisionAmp Web Design'
                        },
                        {
                            first_name: 'John',
                            last_name: 'Doe',
                            email: 'fakeemail123@gmail.com',
                            phone: '000.000.0000',
                            company: 'VisionAmp Web Design'
                        },
                        {
                            first_name: 'John',
                            last_name: 'Doe',
                            email: 'fakeemail123@gmail.com',
                            phone: '000.000.0000',
                            company: 'VisionAmp Web Design'
                        }
                    ]
                }
            ]
        },
        {
            id: 'miscellaneous',
            title: 'Miscellaneous',
            icon: 'fa-folder',
            children: []
        }
    ];

    return (
        <div>
            <PageTitle title="Contact List" />
            <div className="row align-items-center mb-2">
                <div className="col-xl-12 d-flex justify-content-start">
                    <div className={"me-2"}>
                        <Button
                            text="New List"
                            color="red"
                            link="/contact/create/"
                        />
                    </div>
                    <div className="me-2 container-200">
                        <select className={"form-select input-alt"}>
                            <option value="all">A-Z</option>
                        </select>
                    </div>
                    <div className={"create-folder"}>
                        <i className="fas fa-folder"></i> <span className={"btn-link"}>Create New Folder</span>
                    </div>
                </div>
            </div>
            <PageDividerHr className="mb-2" />

            <div className="row">
                <div className="col-12 col-xl-4 ">
                    <FolderAccordion data={folderData} onSelect={handleFolderSelect}/>
                </div>
                <div className="col-xl-8 col-lg-12 d-none d-xl-block custom-hide-lg">
                    {selectedFolder && selectedFolder.data ? (
                        <Table
                            title={selectedFolder.title}
                            subtitle={`Last updated: ${selectedFolder.last_updated}`}
                            data={selectedFolder.data}
                            button={{
                                text: 'Update List with .CSV',
                                icon: 'fa-arrows-rotate',
                                onClick: () => {
                                    console.log('Update List Called')
                                }
                            }}
                        />
                    ) : (
                        <Card className="pt-5 pb-5 d-block">
                            <div className="pt-5 pb-4 d-flex justify-content-center align-content-center">
                                <h3 className="text-gray">Select a Contact to View it Here</h3>
                            </div>
                        </Card>
                    )}
                </div>
            </div>

            {isMobile && selectedFolder && (
                <Modal show={showModal} onClose={handleCloseModal} title={selectedFolder.title}>
                    <Table
                        title={selectedFolder.title}
                        subtitle={`Last updated: ${selectedFolder.last_updated}`}
                        data={selectedFolder.data}
                        button={{
                            text: 'Update List with .CSV',
                            icon: 'fa-arrows-rotate',
                            onClick: () => {console.log('Update List Called')}
                        }}
                    />
                </Modal>
            )};
        </div>
    );
};

export default ContactListHome;
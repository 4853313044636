import React, { useRef, useEffect } from 'react';

const Chart = ({ data, colors, type = 'pie' }) => {
    const canvasRef = useRef(null);

    useEffect(() => {
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');

        const drawChart = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas before drawing

            let startAngle = 0;
            const total = data.reduce((acc, value) => acc + value, 0);

            // Draw each segment of the chart
            data.forEach((value, index) => {
                const sliceAngle = (value / total) * 2 * Math.PI;

                // Draw the outer part of the pie or donut slice
                ctx.beginPath();
                ctx.moveTo(100, 75);
                ctx.arc(100, 75, 75, startAngle, startAngle + sliceAngle);
                ctx.closePath();

                ctx.fillStyle = colors[index];
                ctx.fill();

                startAngle += sliceAngle;
            });

            // Draw the inner hole if it's a donut chart
            if (type === 'donut') {
                ctx.beginPath();
                ctx.arc(100, 75, 40, 0, 2 * Math.PI); // Draws the inner circle to create the hole
                ctx.fillStyle = '#ffffff'; // Fill the hole with white or background color
                ctx.fill();
            }
        };

        drawChart();
    }, [data, colors, type]);

    return <canvas ref={canvasRef} width={200} height={150}></canvas>;
};

export default Chart;
import React from 'react';

const formatHeader = (header) => {
    return header
        .replace(/_/g, ' ')          // Replace underscores with spaces
        .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
};

const Table = ({ title, subtitle, data, button }) => {

    const columns = data.length > 0 ? Object.keys(data[0]) : [];

    return (
        <div className={"card"}>
            {title && (
                <div className="card-header pb-0">
                    <div className={"d-flex justify-content-between"}>
                        <div>
                            <span className="card-title mb-0 h3">{title}</span>
                            {subtitle && <div><span className="card-subtitle text-small-italic">{subtitle}</span></div>}
                        </div>

                        <div className={"d-inline-flex align-items-center"}>
                            {button &&
                                <div className={"me-3"}>
                                    <a onClick={button.onClick}>
                                        {button.icon && <i className={`me-2 text-red fas ${button.icon}`}></i>}
                                        <span className={"btn-link"}>{button.text}</span>
                                    </a>
                                </div>
                            }

                            <nav aria-label="Page navigation">
                                <ul className="pagination custom-pagination">
                                    <li className="page-item"><a className="page-link" href={"/contact/list/"}>1</a>
                                    </li>
                                    <li className="page-item"><a className="page-link" href={"/contact/list/"}>2</a>
                                    </li>
                                    <li className="page-item active"><a className="page-link"
                                                                        href={"/contact/list/"}>3</a></li>
                                    <li className="page-item"><a className="page-link" href={"/contact/list/"}>4</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <hr className="mb-2"/>
                </div>
            )}

            <div className={"card-body pb-0"}>
                <table className="table table-bordered">
                    <thead>
                    <tr>
                        {columns.map((column, index) => (
                            <th key={index}>{formatHeader(column)}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {data.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                            {columns.map((col, colIndex) => (
                                    <td key={colIndex}>{row[col]}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <div className={"card-footer"}>
                <div className={"d-flex justify-content-end"}>
                    <nav aria-label="Page navigation">
                        <ul className="pagination custom-pagination">
                            <li className="page-item"><a className="page-link" href={"/contact/list/"}>1</a>
                            </li>
                            <li className="page-item"><a className="page-link" href={"/contact/list/"}>2</a>
                            </li>
                            <li className="page-item active"><a className="page-link"
                                                                href={"/contact/list/"}>3</a></li>
                            <li className="page-item"><a className="page-link" href={"/contact/list/"}>4</a>
                            </li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    );
}

export default Table;
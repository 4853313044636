import React from 'react';
import PageTitle from '../elements/PageTitle';

function DefaultPage() {
    return (
        <div>
            <PageTitle title="Placeholder" />
            <div className={"alert alert-danger p-3 text-center"}>
                This is a placeholder page.
            </div>
        </div>
    );
}

export default DefaultPage;
import React from 'react';
import Card from "../elements/Card";
import PageTitle from "../elements/PageTitle";
import Chart from "../elements/Chart";
import PageDividerHr from "../elements/PageDividerHr";
import MessageList from "../pages/MessageList";
import Button from "../elements/Button";
import CustomNavTabs from "../custom/CustomNavTabs";
import Carousel from "../elements/Carousel";
import Shortcuts from "../elements/Shortcuts";

const insertLineBreaks = (text, chunkSize) => {
    const regex = new RegExp(`.{1,${chunkSize}}`, 'g');
    return text.match(regex).map((chunk, index) => (
        <React.Fragment key={index}>
            {chunk}
            <br />
        </React.Fragment>
    ));
};

const shortcutItems = [
    { text: 'Co-op', icon: 'fas fa-handshake', link: '/' },
    { text: 'Warranty', icon: 'fas fa-shield-halved', link: '/' },
    { text: 'Sales', icon: 'fas fa-chart-line', link: '/' },
    { text: 'Service', icon: 'fas fa-wrench', link: '/' },
    { text: 'Promotions', icon: 'fas fa-tag', link: '/' }
];

const Dashboard = () => {
    return (
        <div>
            <PageTitle title="Dashboard Home" />

            <div className={"row"}>
                <div className={"col-xl-6 col-lg-12"}>
                    <Card title={"Scorecard"} iconClass={"fas fa-circle-check fa-lg"}>
                        <div className={"row"}>
                            <div className={"col-lg-4 col-md-12 mb-md-0 mb-4"}>
                                <div className={"d-flex justify-content-center align-content-center pb-2"}>
                                    <Chart data={[75, 25]} colors={['#6CD404', '#ccc']} type={'donut'}/>
                                </div>
                                <div className={"mt-2 pb-1 chart-title"}>
                                    <h3>{insertLineBreaks("Mid Year Growth", 16)}</h3>
                                </div>
                                <PageDividerHr noPadding={true}/>
                                <div className={"pt-2"}>
                                    <div className={"text-center"}>
                                        <label className={"b-text me-2"}>Retail:</label>
                                        <span>$350,000</span>
                                    </div>
                                    <div className={"text-center"}>
                                        <label className={"b-text me-2"}>Goal:</label>
                                        <span>$650,000</span>
                                    </div>
                                    <div className={"text-center"}>
                                        <label className={"b-text me-2"}>Current:</label>
                                        <span>$375,000</span>
                                    </div>
                                </div>
                            </div>

                            <div className={"col-lg-4 col-md-12 mb-md-0 mb-4"}>
                                <div className={"d-flex justify-content-center align-content-center pb-2"}>
                                    <Chart data={[60, 40]} colors={['#ffcb05', '#ccc']} type={'donut'}/>
                                </div>
                                <div className={"mt-2 pb-1 chart-title"}>
                                    <h3>{insertLineBreaks("Full Year Growth", 16)}</h3>
                                </div>
                                <PageDividerHr noPadding={true}/>
                                <div className={"pt-2"}>
                                    <div className={"text-center"}>
                                        <label className={"b-text me-2"}>Retail:</label>
                                        <span>$350,000</span>
                                    </div>
                                    <div className={"text-center"}>
                                        <label className={"b-text me-2"}>Goal:</label>
                                        <span>$650,000</span>
                                    </div>
                                    <div className={"text-center"}>
                                        <label className={"b-text me-2"}>Current:</label>
                                        <span>$375,000</span>
                                    </div>
                                </div>
                            </div>

                            <div className={"col-lg-4 col-md-12 mb-md-0 mb-4"}>
                                <div className={"d-flex justify-content-center align-content-center pb-2"}>
                                    <Chart data={[30, 70]} colors={['#ff8153', '#ccc']} type={'donut'}/>
                                </div>
                                <div className={"mt-2 pb-1 chart-title"}>
                                    <h3>{insertLineBreaks("Bonus Stat Here", 16)}</h3>
                                </div>
                                <PageDividerHr noPadding={true}/>
                                <div className={"pt-2"}>
                                    <div className={"text-center"}>
                                        <label className={"b-text me-2"}>Retail:</label>
                                        <span>$350,000</span>
                                    </div>
                                    <div className={"text-center"}>
                                        <label className={"b-text me-2"}>Goal:</label>
                                        <span>$650,000</span>
                                    </div>
                                    <div className={"text-center"}>
                                        <label className={"b-text me-2"}>Current:</label>
                                        <span>$375,000</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>

                    <Card title={"Inbox"} iconClass={"fas fa-message fa-lg"} headerSeparator={false}>
                        <div className={"mt-2"}>
                            <CustomNavTabs type={"messages"} />
                        </div>
                        <div className="mt-4 dashboard-message-list scrollable-c-list">
                            <MessageList message_type="messages" mini={true}/>
                        </div>
                        <PageDividerHr/>
                        <div>
                            <Button text={"VIEW ALL"} link={"/messages/list/"} color={"red"}/>
                        </div>
                    </Card>
                </div>

                <div className={"col-xl-4 col-lg-12"}>
                    <Carousel />

                    <Card title={"Dealer Action Items"} iconClass={"fas fa-bell fa-lg"} headerSeparator={false}>
                        <div className={"mt-2"}>
                            <CustomNavTabs type={"dealer_action_items"}/>
                        </div>
                        <div className="mt-2 scrollable-c-list">
                            <div className="tab-content" id="nav-tabContent">

                                <div className="tab-pane fade show active" id="dealer_action_items-all"
                                     role="tabpanel" aria-labelledby="nav-dealer_action_items-all">
                                    <table className={"table table-bordered"}>
                                        <thead>
                                        <tr>
                                            <th scope="col">Order #:</th>
                                            <th scope="col">Response Required:</th>
                                            <th scope="col">Date:</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>5555555555</td>
                                            <td>Yes</td>
                                            <td>7.10.24</td>
                                            <td className={"text-center"}>
                                                <a href={"/"}><i className={"fas fa-eye fa-lg"}></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>5555555555</td>
                                            <td>Yes</td>
                                            <td>7.10.24</td>
                                            <td className={"text-center"}>
                                                <a href={"/"}><i className={"fas fa-eye fa-lg"}></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>5555555555</td>
                                            <td>Yes</td>
                                            <td>7.10.24</td>
                                            <td className={"text-center"}>
                                                <a href={"/"}><i className={"fas fa-eye fa-lg"}></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>5555555555</td>
                                            <td>Yes</td>
                                            <td>7.10.24</td>
                                            <td className={"text-center"}>
                                                <a href={"/"}><i className={"fas fa-eye fa-lg"}></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>5555555555</td>
                                            <td>Yes</td>
                                            <td>7.10.24</td>
                                            <td className={"text-center"}>
                                                <a href={"/"}><i className={"fas fa-eye fa-lg"}></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>5555555555</td>
                                            <td>Yes</td>
                                            <td>7.10.24</td>
                                            <td className={"text-center"}>
                                                <a href={"/"}><i className={"fas fa-eye fa-lg"}></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>5555555555</td>
                                            <td>Yes</td>
                                            <td>7.10.24</td>
                                            <td className={"text-center"}>
                                                <a href={"/"}><i className={"fas fa-eye fa-lg"}></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>5555555555</td>
                                            <td>Yes</td>
                                            <td>7.10.24</td>
                                            <td className={"text-center"}>
                                                <a href={"/"}><i className={"fas fa-eye fa-lg"}></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>5555555555</td>
                                            <td>Yes</td>
                                            <td>7.10.24</td>
                                            <td className={"text-center"}>
                                                <a href={"/"}><i className={"fas fa-eye fa-lg"}></i></a>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="tab-pane fade" id="dealer_action_items-new" role="tabpanel"
                                     aria-labelledby="nav-dealer_action_items-new">
                                    <table className={"table table-bordered"}>
                                        <thead>
                                        <tr>
                                            <th scope="col">Order #:</th>
                                            <th scope="col">Response Required:</th>
                                            <th scope="col">Date:</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>5555555555</td>
                                            <td>Yes</td>
                                            <td>7.10.24</td>
                                            <td className={"text-center"}>
                                                <a href={"/"}><i className={"fas fa-eye fa-lg"}></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>5555555555</td>
                                            <td>Yes</td>
                                            <td>7.10.24</td>
                                            <td className={"text-center"}>
                                                <a href={"/"}><i className={"fas fa-eye fa-lg"}></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>5555555555</td>
                                            <td>Yes</td>
                                            <td>7.10.24</td>
                                            <td className={"text-center"}>
                                                <a href={"/"}><i className={"fas fa-eye fa-lg"}></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>5555555555</td>
                                            <td>Yes</td>
                                            <td>7.10.24</td>
                                            <td className={"text-center"}>
                                                <a href={"/"}><i className={"fas fa-eye fa-lg"}></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>5555555555</td>
                                            <td>Yes</td>
                                            <td>7.10.24</td>
                                            <td className={"text-center"}>
                                                <a href={"/"}><i className={"fas fa-eye fa-lg"}></i></a>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div className="tab-pane fade" id="dealer_action_items-old" role="tabpanel"
                                     aria-labelledby="nav-dealer_action_items-old">
                                    <table className={"table table-bordered"}>
                                        <thead>
                                        <tr>
                                            <th scope="col">Order #:</th>
                                            <th scope="col">Response Required:</th>
                                            <th scope="col">Date:</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>5555555555</td>
                                            <td>Yes</td>
                                            <td>7.10.24</td>
                                            <td className={"text-center"}>
                                                <a href={"/"}><i className={"fas fa-eye fa-lg"}></i></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>5555555555</td>
                                            <td>Yes</td>
                                            <td>7.10.24</td>
                                            <td className={"text-center"}>
                                                <a href={"/"}><i className={"fas fa-eye fa-lg"}></i></a>
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </Card>
                </div>

                <div className={"col-xl-2 col-lg-12"}>
                    <Shortcuts items={shortcutItems} icon={"fas fa-link fa-lg"} />
                </div>
            </div>
        </div>
    )
        ;
};

export default Dashboard;
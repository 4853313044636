import React from 'react';
import PropTypes from 'prop-types';

const Card = ({ title, subtitle, iconClass, children, className = '', headerSeparator = true }) => {
    return (
        <div className={`card ${className}`}>
            {title && (
                <div className="card-header pb-0 pb-1">
                    <div className="card-actions float-end">
                        {iconClass && <i className={iconClass}></i>}
                    </div>
                    <h3 className="mb-0">{title}</h3>
                    {subtitle && <div><span className="card-subtitle text-small-italic">{subtitle}</span></div>}
                    {headerSeparator && <hr className="mb-2"/>}
                </div>
            )}
            <div className="card-body">
                {children}
            </div>
        </div>
    );
};

Card.propTypes = {
    title: PropTypes.string,
    iconClass: PropTypes.string,
    children: PropTypes.node.isRequired,
    className: PropTypes.string
};

export default Card;
import React, { useState } from 'react';
import Button from "../elements/Button";

const slides = [
    {
        title: "Toro Days Sales Event",
        subtitle: "Subtitle/message for the slide goes here. This will be limited by character count, and each slide will have the ability to link off to a unique page in dealer zone.",
        image: "https://spartanmowers.com/images/uploads/20240201/orig_a947a5be93e773f460351fdaa134133008c4c255.webp"
    },
    {
        title: "Another Topic Here",
        subtitle: "Subtitle/message for the slide goes here.",
        image: "https://spartanmowers.com/images/uploads/20240201/orig_bb9440d807af9c8e5ac570bfacf8fe81c08c3f76.webp"
    }
];

const CarouselSlide = ({ slide, currentSlide, totalSlides, onPrev, onNext }) => {
    return (
        <div className="card carousel-card">
            <div className="row g-0 d-flex justify-content-center">
                <div className="col-md-12 position-relative carousel-image-container">
                    <div className={"image-wrapper"}>
                        <img src={slide.image} alt="Toro equipment"
                             className="img-fluid w-100 h-100 object-fit-cover carousel-image"/>
                    </div>
                </div>

                <div className="col-md-10 mt-3 ps-md-4 ps-lg-0">
                    <div className="d-flex align-items-center ms-3 ms-md-0">
                        <i className="fa-light fa-chevron-left text-red fa-2xl me-3" onClick={onPrev}></i>
                        <span className="me-3">
                            {currentSlide + 1} of {totalSlides}
                        </span>
                        <i className="fa-light fa-chevron-right text-red fa-2xl" onClick={onNext}></i>
                    </div>
                    <hr className="mt-3"/>
                </div>


                <div className="col-md-11">
                    <div className="card-body d-flex flex-column h-100">
                        <h3 className="card-title fs-4 fw-bold mb-3">{slide.title}</h3>
                        <p className="card-text mb-4">{slide.subtitle}</p>
                        <div className={"col-md-6"}>
                            <Button text={"VIEW DETAILS"} color={"red"} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Carousel = () => {
    const [currentSlide, setCurrentSlide] = useState(0);

    const nextSlide = () => {
        setCurrentSlide((prev) => (prev + 1) % slides.length);
    };

    const prevSlide = () => {
        setCurrentSlide((prev) => (prev - 1 + slides.length) % slides.length);
    };

    return (
        <div>
            <div className="position-relative">
                <CarouselSlide
                    slide={slides[currentSlide]}
                    currentSlide={currentSlide}
                    totalSlides={slides.length}
                    onPrev={prevSlide}
                    onNext={nextSlide}
                />
            </div>
        </div>
    );
};

export default Carousel;
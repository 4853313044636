import React, { useState, useEffect, useRef } from 'react';
import Card from '../elements/Card';

const MessageContentBuilder = () => {
    const [sections, setSections] = useState([]);
    const contentEndRef = useRef(null);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 576);

    const renderSection = (section) => {
        switch (section.type) {
            case 'h1':
                return (
                    <div key={section.id} className="row mb-2 align-items-center">
                        <div className="col-11">
                            <h1 contentEditable="true" className="editable">Add your message header here</h1>
                        </div>
                        <div className="col-1">
                            <i className="fa fa-times-circle text-danger fa-2x cursor-pointer" onClick={() => handleRemoveSection(section.id)}></i>
                        </div>
                    </div>
                );
            case 'h2':
                return (
                    <div key={section.id} className="row mb-2 align-items-center">
                        <div className="col-11">
                            <h2 contentEditable="true" className="editable">Add your message subheader here</h2>
                        </div>
                        <div className="col-1">
                            <i className="fa fa-times-circle text-danger fa-2x cursor-pointer" onClick={() => handleRemoveSection(section.id)}></i>
                        </div>
                    </div>
                );
            case 'text':
                return (
                    <div key={section.id} className="row mb-2 align-items-center">
                        <div className="col-11">
                            <p contentEditable="true" className="editable">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sit amet.</p>
                        </div>
                        <div className="col-1">
                            <i className="fa fa-times-circle text-danger fa-2x cursor-pointer" onClick={() => handleRemoveSection(section.id)}></i>
                        </div>
                    </div>
                );
            case 'bullet-list':
                return (
                    <div key={section.id} className="row mb-2 align-items-center">
                        <div className="col-11">
                            <ul>
                                {section.bullets.map((bullet, index) => (
                                    <li key={index} contentEditable="true" className="editable">
                                        {bullet}
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="col-1">
                            <i className="fa fa-times-circle text-danger fa-2x cursor-pointer" onClick={() => handleRemoveSection(section.id)}></i>
                        </div>
                    </div>
                );
            case 'image':
                return (
                    <div key={section.id} className="row mb-2 align-items-center">
                        <div className="col-11">
                            <input type="file" className="form-control" accept="image/*" />
                        </div>
                        <div className="col-1">
                            <i className="fa fa-times-circle text-danger fa-2x cursor-pointer" onClick={() => handleRemoveSection(section.id)}></i>
                        </div>
                    </div>
                );
            case 'button':
                return (
                    <div key={section.id} className="row mb-2 align-items-center">
                        <div className="col-11">
                            <input type="button" value="Button" className="btn btn-primary" />
                        </div>
                        <div className="col-1">
                            <i className="fa fa-times-circle text-danger fa-2x cursor-pointer" onClick={() => handleRemoveSection(section.id)}></i>
                        </div>
                    </div>
                );
            case 'hr':
                return (
                    <div key={section.id} className="row mb-2 align-items-center">
                        <div className="col-11">
                            <hr />
                        </div>
                        <div className="col-1">
                            <i className="fa fa-times-circle text-danger fa-2x cursor-pointer" onClick={() => handleRemoveSection(section.id)}></i>
                        </div>
                    </div>
                );
            default:
                return null;
        }
    };

    // Monitor screen width and update state
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 576);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleAddSection = (datatype) => {
        const newSection = {
            id: Date.now(),
            type: datatype,
            bullets: datatype === 'bullet-list' ? ['List item 1', 'List item 2', 'List item 3', 'List item 4'] : null,
        };
        setSections([...sections, newSection]);
    };

    const handleRemoveSection = (id) => {
        setSections(sections.filter((section) => section.id !== id));
    };

    // Auto-scroll to the bottom whenever sections are added or removed
    useEffect(() => {
        const scrollToBottom = () => {
            contentEndRef.current?.scrollIntoView({ behavior: 'instant'});
        };
        const timeoutId = setTimeout(scrollToBottom, 100);
        return () => clearTimeout(timeoutId);
    }, [sections]);

    // Render dropdown for mobile
    const renderDropdown = () => (
            <div>
                <label className={"form-label b-text mt-3"}>
                    Add a Section
                </label>
                <select
                    className="form-select input-main mb-3"
                    onChange={(e) => handleAddSection(e.target.value)}
                    defaultValue="default"
                >
                    <option value="default" disabled>Select Section to Add</option>
                    <option value="h1">Add Header</option>
                    <option value="h2">Add Sub Title</option>
                    <option value="text">Add Text</option>
                    <option value="bullet-list">Add Bullet List</option>
                    <option value="image">Add Image</option>
                    <option value="button">Add Button</option>
                    <option value="hr">Add Divider</option>
                </select>
            </div>

    );

    // Render icons for desktop
    const renderIcons = () => (
        <div className="d-flex justify-content-center mt-4 mb-3">
            <div className="mt-1 me-4">
                <h4>Add a Section</h4>
            </div>
            <a href="#" onClick={() => handleAddSection('h1')} title="Add Header">
                <i className="fa-solid fa-h1 fa-2x me-3"></i>
            </a>
            <a href="#" onClick={() => handleAddSection('h2')} title="Add Sub Title">
                <i className="fa-solid fa-h2 fa-2x me-3"></i>
            </a>
            <a href="#" onClick={() => handleAddSection('text')} title="Add Text">
                <i className="fa-solid fa-text fa-2x me-3"></i>
            </a>
            <a href="#" onClick={() => handleAddSection('bullet-list')} title="Add Bullet List">
                <i className="fa-solid fa-list fa-2x me-3"></i>
            </a>
            <a href="#" onClick={() => handleAddSection('image')} title="Add Image">
                <i className="fa-solid fa-image fa-2x me-3"></i>
            </a>
            <a href="#" onClick={() => handleAddSection('button')} title="Add Button">
                <i className="fa-solid fa-rectangle fa-2x me-3"></i>
            </a>
            <a href="#" onClick={() => handleAddSection('hr')} title="Add Divider">
                <i className="fa-solid fa-file-dashed-line fa-2x me-3"></i>
            </a>
        </div>
    );

    return (
        <Card title="Message Content" iconClass="fas fa-fw fa-envelope-open-text fa-lg">
            {/* Content Builder Section */}
            <div id="content-builder">
                {sections.map((section) => renderSection(section))}
                {/* Ref to scroll into view */}
                <div ref={contentEndRef}></div>
            </div>

            {/* Section Selector under the content */}
            {isMobile ? renderDropdown() : renderIcons()}
        </Card>
    );
};

export default MessageContentBuilder;
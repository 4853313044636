import React, { useState, useEffect } from 'react';
import { Loader2 } from 'lucide-react'; // Importing a loading icon

const TagList = () => {
    const [tags, setTags] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchTags = async () => {
            try {
                setLoading(true);
                const response = await fetch('https://toroportal.volanosoftware.com/api/v1/Tags');
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                setTags(data);
            } catch (error) {
                console.error("There was a problem fetching the tags:", error);
                setError('Failed to load tags. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchTags();
    }, []);

    if (loading) {
        return (
            <div className="d-flex justify-content-center align-items-center" style={{ height: '115px' }}>
                <Loader2 className="animate-spin h-8 w-8 text-blue-500" />
                <span className="ml-2"> Loading tags...</span>
            </div>
        );
    }

    if (error) {
        return (
            <div>{error}</div>
        );
    }

    return (
        <div className="row tag-container">
            {tags.map((tag) => (
                <div className="col-6" key={tag.id}>
                    <div className="form-check">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id={`tag_${tag.id}`}
                            name="tags[]"
                            value={tag.id}
                            data-title={tag.title}
                            data-color={tag.color}
                        />
                        <label className="form-check-label" htmlFor={`tag_${tag.id}`}>
                            {tag.title}
                        </label>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default TagList;
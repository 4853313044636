import React from 'react';
import PageTitle from '../elements/PageTitle';
import Card from '../elements/Card';
import PageDividerHr from '../elements/PageDividerHr';
import Button from '../elements/Button';
import FileUpload from '../process/fileUploader';

const Support = () => {

    return (
        <div>
            <PageTitle title={"Support"} />

            <div className={"row"}>
                <div className={"col-sm-8"}>
                    <Card title={"What can we help you with?"} iconClass={"fas fa-circle-question fa-lg"}>
                        <div>Please fill out the required fields below to get your issue directly into the hands of our professional support staff.</div>
                        <PageDividerHr />

                        <div className={"row mb-3"}>
                            <div className={"col-sm-12"}>
                                <label htmlFor="category" className="form-label b-text">
                                    <span className="text-red">*</span> I Need Help With...
                                </label>
                                <select className="form-select input-main" name={"category"} id={"category"}>
                                    <option value={""} selected>Select a Category</option>
                                    <option value="1">Example 1</option>
                                    <option value="2">Example 2</option>
                                    <option value="3">Example 3</option>
                                </select>
                            </div>
                        </div>

                        <div className={"row mb-3"}>
                            <div className={"col-sm-5"}>
                                <label htmlFor="phone_number" className="form-label b-text">
                                    <span className="text-red">*</span> Contact Phone Number
                                </label>
                                <input
                                    type="text"
                                    className="form-control input-main"
                                    name="phone_number"
                                    id="phone_number"
                                    placeholder="Phone"
                                    value={""}
                                    required
                                />
                            </div>

                            <div className={"col-sm-7"}>
                                <label htmlFor="email" className="form-label b-text">
                                    <span className="text-red">*</span> Contact Email
                                </label>
                                <input
                                    type="email"
                                    className="form-control input-main"
                                    name="email"
                                    id="email"
                                    placeholder="Email"
                                    value={""}
                                    required
                                />
                            </div>
                        </div>

                        <div className={"row mb-3"}>
                            <div className={"col-sm-12"}>
                                <label htmlFor="subject" className="form-label b-text">
                                    <span className="text-red">*</span> Subject (Short but Descriptive)
                                </label>
                                <input
                                    type="text"
                                    className="form-control input-main"
                                    name="subject"
                                    id="subject"
                                    placeholder="Subject"
                                    value={""}
                                    required
                                />
                            </div>
                        </div>

                        <div className={"row mb-3"}>
                            <FileUpload label={"Upload a Screenshot (Optional - 5MB max.)"} button_text={"CHOOSE A FILE"} />
                        </div>

                        <div className={"row"}>
                            <div className={"col-sm-12"}>
                                <label htmlFor="additional_info" className="form-label b-text">
                                    <span className="text-red">*</span> Additional Information
                                </label>
                                <textarea
                                    className="form-control input-main"
                                    name="additional_info"
                                    id="additional_info"
                                    placeholder="Enter additional information here"
                                    rows={5}
                                    value={""}
                                    required
                                />
                            </div>

                            <div className={"col-sm-4 mt-3"}>
                                <Button color={"red"} text={"SUBMIT"}/>
                            </div>
                        </div>

                    </Card>
                </div>

                <div className={"col-sm-4"}>
                    <Card title={"Active Tickets"} iconClass={"fas fa-check fa-lg"}>

                        <div className={"mb-3"}>These are all the tickets you've recently submitted for review. Someone from the DealerZone development team will be reaching out to you soon to resolve your tickets. If a solution is not met within 30 days of the ticket submission, the ticket will be automatically moved to your 'Resolved Tickets' section.</div>

                        <div className="d-flex justify-content-between mb-1">
                            <div className={"d-flex justify-content-start"}>
                                <a href={"#"} className={"link"}>123123123123123000000</a>
                            </div>

                            <div className={"d-flex justify-content-end"}>
                                <span className={"text-gray me-1"}>Submitted On:</span>
                                <span className={"text-dark-gray"}> 06/15/2023</span>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between mb-1">
                            <div className={"d-flex justify-content-start"}>
                                <a href={"#"} className={"link"}>123123123123123000000</a>
                            </div>

                            <div className={"d-flex justify-content-end"}>
                                <span className={"text-gray me-1"}>Submitted On:</span>
                                <span className={"text-dark-gray"}> 06/15/2023</span>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between mb-1">
                            <div className={"d-flex justify-content-start"}>
                                <a href={"#"} className={"link"}>123123123123123000000</a>
                            </div>

                            <div className={"d-flex justify-content-end"}>
                                <span className={"text-gray me-1"}>Submitted On:</span>
                                <span className={"text-dark-gray"}> 06/15/2023</span>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between">
                            <div className={"d-flex justify-content-start"}>
                                <a href={"#"} className={"link"}>123123123123123000000</a>
                            </div>

                            <div className={"d-flex justify-content-end"}>
                                <span className={"text-gray me-1"}>Submitted On:</span>
                                <span className={"text-dark-gray"}> 06/15/2023</span>
                            </div>
                        </div>

                    </Card>

                    <Card title={"Resolved Tickets"} iconClass={"fas fa-check fa-lg"}>

                        <div className={"mb-3"}>These are all the tickets you've submitted that have been resolved or remained open for longer than 30 days.</div>

                        <div className="d-flex justify-content-between mb-1">
                            <div className={"d-flex justify-content-start"}>
                                <a href={"#"} className={"link"}>123123123123123000000</a>
                            </div>

                            <div className={"d-flex justify-content-end"}>
                                <span className={"text-gray me-1"}>Submitted On:</span>
                                <span className={"text-dark-gray"}> 06/15/2023</span>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between mb-1">
                            <div className={"d-flex justify-content-start"}>
                                <a href={"#"} className={"link"}>123123123123123000000</a>
                            </div>

                            <div className={"d-flex justify-content-end"}>
                                <span className={"text-gray me-1"}>Submitted On:</span>
                                <span className={"text-dark-gray"}> 06/15/2023</span>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between mb-1">
                            <div className={"d-flex justify-content-start"}>
                                <a href={"#"} className={"link"}>123123123123123000000</a>
                            </div>

                            <div className={"d-flex justify-content-end"}>
                                <span className={"text-gray me-1"}>Submitted On:</span>
                                <span className={"text-dark-gray"}> 06/15/2023</span>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between">
                            <div className={"d-flex justify-content-start"}>
                                <a href={"#"} className={"link"}>123123123123123000000</a>
                            </div>

                            <div className={"d-flex justify-content-end"}>
                                <span className={"text-gray me-1"}>Submitted On:</span>
                                <span className={"text-dark-gray"}> 06/15/2023</span>
                            </div>
                        </div>

                    </Card>

                    <Card title={"Contact Support"} iconClass={"fas fa-phone fa-lg"}>
                        <div className="d-flex flex-column gap-2">
                            <div>T: 870.580.0909</div>
                            <div>F: 555.555.5555</div>
                            <div>H: Mon - Thu | 8AM - 5PM</div>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default Support;
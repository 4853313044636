import React from 'react';
import ReactDOM from 'react-dom';
import Button from './Button';
import PageDividerHr from "./PageDividerHr";

export const renderMessageContent = (content) => {
    return content.map((section, index) => {
        switch (section.type) {
            case 'h1':
                return (
                    <div key={index} className="row mb-2">
                        <div className="col-12">
                            <h1>{section.content}</h1>
                        </div>
                    </div>
                );
            case 'subtitle':
                return (
                    <div key={index} className="row mb-2">
                        <div className="col-12">
                            <h3>{section.content}</h3>
                        </div>
                    </div>
                );
            case 'text':
                return (
                    <div key={index} className="row mb-2">
                        <div className="col-12">
                            <p>{section.content}</p>
                        </div>
                    </div>
                );
            case 'bullet-list':
                return (
                    <div key={index} className="row mb-2">
                        <div className="col-12">
                            <ul>
                                {section.bullets.map((bullet, idx) => (
                                    <li key={idx}>{bullet}</li>
                                ))}
                            </ul>
                        </div>
                    </div>
                );
            case 'image':
                return (
                    <div key={index} className="row mb-2">
                        <div className="col-12">
                            <img src={section.src} alt={section.alt} className="img-fluid w-25" />
                        </div>
                    </div>
                );
            case 'button':
                return (
                    <div key={index} className="row mb-2">
                        <div className="col-12">
                            <a href={section.href} target={section.target} className="btn btn-primary">
                                {section.content}
                            </a>
                        </div>
                    </div>
                );
            case 'hr':
                return (
                    <div key={index} className="row mb-2">
                        <div className="col-12">
                            <hr />
                        </div>
                    </div>
                );
            case 'attachments':
                const fileTypeIcons = {
                    pdf: { class: 'fas fa-fw fa-file-pdf fa-lg me-2 text-red', label: 'PDF' },
                    text: { class: 'fas fa-fw fa-file-word fa-lg me-2 text-blue', label: 'Text' },
                    csv: { class: 'fas fa-fw fa-file-excel fa-lg me-2 text-green', label: 'CSV' },
                    'power-point': { class: 'fas fa-fw fa-file-powerpoint fa-lg me-2 text-pink', label: 'PowerPoint' },
                    svg: { class: 'fas fa-fw fa-file-image fa-lg me-2 text-orange', label: 'Image' },
                };

                return (
                    <div key={index} className="row mb-2">
                        <div className="col-12">
                            <h4>Attachments:</h4>
                            {section.files.map((file, idx) => (
                                <div key={idx} className="attachment-item">
                                    <i className={fileTypeIcons[file.type]?.class || 'fas fa-fw fa-file'}></i>
                                    <a href={file.href} target="_blank" rel="noopener noreferrer">
                                        {file.name}
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                );
            default:
                return null;
        }
    });
};

const formatDate = (dateString) => {
    const date = new Date(dateString);

    let month = date.getMonth() + 1;
    let day = date.getDate();
    let year = date.getFullYear();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let period = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12 || 12;

    minutes = minutes < 10 ? `0${minutes}` : minutes;

    day = day < 10 ? `0${day}` : day;
    month = month < 10 ? `0${month}` : month;

    return `${month}/${day}/${year} - ${hours}:${minutes}${period}`;
}

export const openMessage = (message, type) => {
    const displayContainer = document.querySelector('.message-display');

    if (!displayContainer) {
        console.error("Display container with class 'message-display' not found.");
        return;
    }

    // Ensure that the display container is visible and in the right location
    displayContainer.style.position = 'absolute';
    displayContainer.style.transition = 'top 0.3s ease';
    displayContainer.style.marginRight = '40px';

    // Get the position of the clicked message item in the list
    const messageList = document.getElementById(message.id + '_mlist');
    if (!messageList) {
        console.error(`Message list item with ID ${message.id}_mlist not found.`);
        return;
    }

    // Function to position the caret and container
    const positionCaretAndContainer = () => {
        const messageListRect = messageList.getBoundingClientRect();
        const containerRect = displayContainer.getBoundingClientRect();
        const messageListTop = messageListRect.top + window.scrollY;

        // Update the top position of the display container
        displayContainer.style.top = `${messageListTop}px`;

        // Add caret to the display container if it doesn't exist
        let caret = displayContainer.querySelector('.message-caret');
        if (!caret) {
            caret = document.createElement('div');
            caret.className = 'message-caret';
            displayContainer.insertBefore(caret, displayContainer.firstChild);
        }

    };

    // Initial positioning
    positionCaretAndContainer();

    // Reposition after a short delay to ensure all elements are rendered
    setTimeout(positionCaretAndContainer, 50);

    // Scroll the display container to the top of the viewport
    window.scrollTo({
        top: messageList.getBoundingClientRect().top + window.scrollY - 20,
        behavior: 'smooth',
    });

    const tagsHtml = message.tags && message.tags.length > 0 ? (
        <div className="d-inline-flex">
            {message.tags.map((tag) => (
                <span key={tag.id} className={`tag tag-${tag.color} me-1`}>
                    {tag.title}
                </span>
            ))}
        </div>
    ) : null;

    const lockedIcon = message.lock_message ? <i className="fas fa-fw fa-lock fa-lg me-2"></i> : null;
    let attachmentCount;
    if(message.attachments){
        attachmentCount = message.attachments.length;
    }
    const pinnedClass = message.pinned ? 'text-white' : 'text-light-gray';
    const pinnedCircleClass = message.pinned ? 'active-pin' : '';

    let attachmentBadge = attachmentCount > 0 ? (
        <span className="badge rounded-pill bg-white text-primary me-2">{attachmentCount}</span>
    ) : null;

    const attachmentClass = attachmentCount > 0 ? '' : 'text-light-gray';


    let headerHtml;

    if(type === 'messages'){
        headerHtml = (
            <div className={"d-flex justify-content-center align-items-center"}>
                <h3 className={"me-4 mt-2"}>Mark as Read?</h3>
                <Button color={"red"} text={"YES"}/>
            </div>
        );
    } else if (type === 'drafts') {
        headerHtml = (
            <div className={"d-flex justify-content-center align-items-center"}>
                <h3 className={"me-4 mt-2"}>Submit for Approval?</h3>
                <Button color={"red"} text={"SUBMIT"}/>
            </div>
        );
    } else {
        if(message.status === 'pending_approval'){
            headerHtml = (
                <div className={"d-flex justify-content-center align-items-center"}>
                    <h3 className={"me-4 mt-2"}>Mark approved?</h3>
                    <div className={"me-2"}>
                        <Button color={"blue"} text={"YES"}/>
                    </div>
                    <div className={"me-3"}>
                        <Button color={"red"} text={"NO"}/>
                    </div>
                    <h3 className={"mt-2"}>3/5 Approved</h3>
                </div>
            );
        } else if (message.status === 'approved') {
            headerHtml = (
                <div className={"d-flex justify-content-center align-items-center"}>
                    <div className={"me-4"}>
                        <Button color={"red"} text={"SEND NOW"}/>
                    </div>
                    <h3 className={"mt-3 me-4"}>-OR-</h3>
                    <input
                        className="input-main me-3"
                        type="date"
                        id="schedule_date"
                        name="schedule_date"
                    />
                    <input
                        className="input-main me-4"
                        type="time"
                        id="schedule_time"
                        name="schedule_time"
                    />
                    <div className={"me-2"}>
                        <Button color={"red"} text={"SCHEDULE"}/>
                    </div>
                </div>
            );
        } else {
            headerHtml = (
                <div className={"d-flex justify-content-center align-items-center"}>
                    <div className={"me-3"}>
                        <Button color={"red"} text={"SEND TO DRAFTS"}/>
                    </div>
                    <h3 className={"me-4 mt-2"}>-OR-</h3>
                    <div className={"me-2"}>
                        <Button color={"red"} text={"DELETE"}/>
                    </div>
                </div>
            );
        }
    }

    return (
        <div>
            <div>
                {headerHtml}
            </div>
            <div className={"message-caret"}></div>
            <PageDividerHr/>
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <div
                        className={`${message.mark_as_read ? 'read-message-icon' : 'unread-message-icon'} d-flex align-items-center justify-content-center me-2`}>
                        <i className={message.mark_as_read ? 'fas fa-fw fa-envelope-open fa-lg' : 'fas fa-fw fa-envelope fa-lg'}></i>
                    </div>
                    <div>
                        <h4 className="message-title font-weight-bold mb-0">{message.title}</h4>
                        <div className="text-muted text-small-italic">{formatDate(message.submit_date)}</div>
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <div>
                        {lockedIcon}
                        <div className="icon-badge-container">
                            <i className={`fas fa-fw fa-paperclip fa-lg me-3 ${attachmentClass}`}></i>
                            {attachmentBadge}
                        </div>
                        <span className={`icon-circle ${pinnedCircleClass}`}>
                        <i className={`fas fa-fw fa-map-pin fa-lg ${pinnedClass}`}></i>
                    </span>
                    </div>
                </div>
            </div>
            <PageDividerHr/>
            {tagsHtml}
            <PageDividerHr/>
            {renderMessageContent(message.content)}
            <PageDividerHr/>
        </div>
    );
};



const Message = ({message, type, isSelected, onSelect, onPinToggle}) => {

    if (!message) {
        console.error("Message is undefined:", message);
        return null;  // Return null or some fallback UI if message is undefined
    }

    const handlePinClick = (e) => {
        e.stopPropagation(); // Prevent the overall message click event from firing
        onPinToggle(message.id); // Call the prop function to handle the pin toggle
    };

    let readClass;
    let readIconClass;
    let readIcon;

    const selectedClass = isSelected ? 'selected-message-outline' : '';

    if(type === 'messages'){
        readClass = message.mark_as_read ? 'read-message' : 'unread-message';
        readIconClass = message.mark_as_read ? 'read-message-icon' : 'unread-message-icon';
        readIcon = message.mark_as_read ? 'fas fa-fw fa-envelope-open fa-lg' : 'fas fa-fw fa-envelope fa-lg';
    } else if (type === 'drafts') {
        readClass = 'draft';
        readIconClass = 'draft-icon';
        readIcon = 'fas fa-fw fa-compass-drafting fa-lg';
    } else if (type === 'pending'){
        readClass = 'draft';
        if(message.status === 'pending_approval'){
            readIconClass = 'draft-icon';
            readIcon = 'fas fa-fw fa-spinner fa-lg';
        } else if (message.status === 'approved'){
            readIconClass = 'approved-draft-icon';
            readIcon = 'fas fa-fw fa-check fa-lg';
        } else if (message.status === 'denied'){
            readIconClass = 'denied-draft-icon';
            readIcon = 'fas fa-fw fa-times fa-lg';
        }
    }


    const lockedIcon = message.lock_message ? <i className="fas fa-fw fa-lock fa-lg me-2"></i> : null;
    let attachmentCount;
    if(message.attachments){
        attachmentCount = message.attachments.length;
    }
    const pinnedClass = message.pinned ? 'text-white' : 'text-light-gray';
    const pinnedCircleClass = message.pinned ? 'active-pin' : '';

    let attachmentBadge = attachmentCount > 0 ? (
        <span className="badge rounded-pill bg-white text-primary me-2">{attachmentCount}</span>
    ) : null;

    const attachmentClass = attachmentCount > 0 ? '' : 'text-light-gray';

    let tagsHtml = message.tags.length > 0 ? (
        <div className="tag-container mt-2">
            {message.tags.slice(0, 3).map((tag) => (
                <div key={tag.id} className={`tag tag-${tag.color}`}>{tag.title}</div>
            ))}
            {message.tags.length > 3 && <div className="tag tag-gray">+{message.tags.length - 3}</div>}
        </div>
    ) : null;

    // Add the `onClick` to trigger `openMessage` when this message is clicked
    return (
        <div className={`message-item ${readClass} ${selectedClass}`} onClick={onSelect} id={`${message.id}_mlist`}>
            <div className="message-header">
                <div className={`${readIconClass} d-flex align-items-center justify-content-center`}>
                    <i className={readIcon}></i>
                </div>
                <div className="message-details">
                    <h4 className="message-item-title font-weight-bold mb-0 mt-1">{message.title}</h4>
                    {message.submit_date === undefined ? <div className="text-muted text-small-italic message-date">{formatDate(message.draft_date)}</div> :
                    <div className="text-muted text-small-italic message-date">{formatDate(message.submit_date)}</div>}
                    <div className={"message-tag-container"}>
                        {tagsHtml}
                    </div>
                </div>
            </div>
            <div className="message-controls">
                <div>
                    {lockedIcon}
                    <div className="icon-badge-container">
                        <i className={`fas fa-fw fa-paperclip fa-lg me-3 ${attachmentClass}`}></i>
                        {attachmentBadge}
                    </div>
                    <span className={`icon-circle ${pinnedCircleClass}`} onClick={handlePinClick}>
                        <i className={`fas fa-fw fa-map-pin fa-lg ${pinnedClass}`}></i>
                    </span>
                </div>
            </div>
        </div>
    );
};

export default Message;
import React, { useState, useEffect, useRef } from 'react';

const tabConfigs = {
    messages: [
        { id: 'all', label: 'All' },
        { id: 'read', label: 'Read' },
        { id: 'unread', label: 'Unread' }
    ],
    dealer_action_items: [
        { id: 'all', label: 'All' },
        { id: 'new', label: 'New' },
        { id: 'old', label: 'Old' }
    ]
};

const CustomNavTabs = ({ type = 'messages' }) => {
    const tabs = tabConfigs[type] || tabConfigs.messages;
    const [activeTab, setActiveTab] = useState(tabs[0].id);
    const tabsRef = useRef({});
    const indicatorRef = useRef(null);

    const updateIndicator = (tab) => {
        const currentTab = tabsRef.current[tab];
        if (currentTab && indicatorRef.current) {
            indicatorRef.current.style.width = `${currentTab.offsetWidth}px`;
            indicatorRef.current.style.left = `${currentTab.offsetLeft}px`;
        }
    };

    useEffect(() => {
        updateIndicator(activeTab);
    }, [activeTab]);

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    return (
        <nav>
            <div className="custom-nav-tabs" id={`${type}-nav-tab`} role="tablist">
                {tabs.map((tab) => (
                    <a
                        key={tab.id}
                        ref={(el) => (tabsRef.current[tab.id] = el)}
                        className={`custom-nav-link ${activeTab === tab.id ? 'active' : ''}`}
                        onClick={() => handleTabClick(tab.id)}
                        id={`nav-${type}-${tab.id}`}
                        data-bs-toggle="tab"
                        data-bs-target={`#${type}-${tab.id}`}
                        role="tab"
                        aria-controls={`${type}-${tab.id}`}
                        aria-selected={activeTab === tab.id}
                    >
                        {tab.label}
                    </a>
                ))}
                <div ref={indicatorRef} className="sliding-indicator"></div>
            </div>
        </nav>
    );
};

export default CustomNavTabs;
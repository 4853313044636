import React from 'react';
import Card from "../elements/Card";
import Button from "../elements/Button";
import FormDropdown from "../elements/FormDropdown";
import PageDividerHr from "../elements/PageDividerHr";
import PageTitle from "../elements/PageTitle";

const Test = () => {
    return (
        <div>
            <PageTitle title={"Test Title"} />
            <Card title="Test Card">
                <Button color={"red"} text={"Test Button"} />
                <FormDropdown title={"Test Dropdown"} options={[{label: "Option 1", value: "option1"}, {label: "Option 2", value: "option2"}]} />
                <PageDividerHr />
            </Card>
        </div>
    );
};

export default Test;
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';

const Modal = ({ show, onClose, title, children }) => {
    useEffect(() => {
        const handleClickOutside = (event) => {
            const modal = document.querySelector('.modal-content');
            const backdrop = document.querySelector('.modal-backdrop');

            // Check if the modal is open and the clicked target is outside the modal
            if (modal && backdrop && !modal.contains(event.target) && backdrop.contains(event.target)) {
                // Call the onClose function to close the modal
                onClose();
            }
        };

        // Add the event listener when the modal is shown
        document.addEventListener('click', handleClickOutside);

        // Clean up the event listener when the modal is closed
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [onClose]);

    if (!show) {
        return null;
    }

    return ReactDOM.createPortal(
        <div className="modal-backdrop">
            <div className="modal-content">
                <button type="button" className="close" onClick={onClose}>
                    <span><i className="fa-light fa-times fa-lg"></i></span>
                </button>
                <div className="modal-body">
                    {children}
                </div>
            </div>
        </div>,
        document.body
    );
};

export default Modal;
import React, { useState } from 'react';
import PageTitle from "../elements/PageTitle";
import Card from "../elements/Card";
import PageDividerHr from "../elements/PageDividerHr";
import Button from "../elements/Button";

const FeatureRequest = () => {
    const [featureVotes, setFeatureVotes] = useState({
        1: { approved: false, denied: false },
        2: { approved: false, denied: false },
        3: { approved: false, denied: false },
    });

    const handleVote = (id, voteType) => {
        setFeatureVotes(prevVotes => ({
            ...prevVotes,
            [id]: {
                approved: voteType === 'approve' ? !prevVotes[id].approved : false,
                denied: voteType === 'deny' ? !prevVotes[id].denied : false,
            }
        }));
    };

    const renderFeatureRequest = (id, votes, subject, description) => (
        <>
            <div className="row">
                <div className="col-sm-2 d-flex flex-column align-items-center justify-content-center">
                    <div
                        className={`icon-square ${featureVotes[id].approved ? 'approved' : ''} mb-3`}
                        onClick={() => handleVote(id, 'approve')}
                    >
                        <i className="fas fa-arrow-up fa-lg"></i>
                    </div>

                    <div className="text-center">
                        <h1 className="mb-0">{votes}</h1>
                        <span className="d-block">VOTES</span>
                    </div>

                    <div
                        className={`icon-square ${featureVotes[id].denied ? 'denied' : ''} mt-3`}
                        onClick={() => handleVote(id, 'deny')}
                    >
                        <i className="fas fa-arrow-down fa-lg"></i>
                    </div>
                </div>

                <div className="col-sm-10">
                    <label className="form-label b-text">{subject}</label>
                    <p>{description}</p>
                </div>
            </div>
            <PageDividerHr />
        </>
    );


    return (
        <div>
            <PageTitle title="Feature Request" />
            <div className={"row"}>
                <div className={"col-sm-7"}>
                    <Card title={"Do you have a great feature idea?"} iconClass={"fas fa-pencil fa-lg"}>
                        <span>Please fill our the required fields below in order to submit a feature request. If you aren't sure if your feature has been thought of before, browse the other features that users have submitted and voted on.</span>

                        <div className={"mt-3"}>
                            <label className="form-label b-text">Helpful Tips:</label>
                            <div className={"d-flex flex-column gap-2"}>
                                <span>1. Be as descriptive and clear as you can.</span>
                                <span>2. Select a feature category that matches where your feature goes as best as possible.</span>
                                <span>3. Vote on other ideas you like.</span>
                            </div>
                        </div>

                        <PageDividerHr/>
                        <div className={"mt-3"}>
                            <form>
                                <div className="mb-3">
                                    <span className={"asterisk"}>*</span><label className="form-label b-text">Select a Topic</label><br></br>
                                    <select className="form-select input-main" id="featureTopic">
                                        <option selected>Select a Topic</option>
                                        <option value="1">User Interface</option>
                                        <option value="2">User Experience</option>
                                        <option value="3">Performance</option>
                                        <option value="4">Other</option>
                                    </select>
                                </div>

                                <div className="mb-3">
                                    <span className={"asterisk"}>*</span><label className="form-label b-text">Subject
                                    (Short and Descriptive)</label><br></br>
                                    <input type="text" className="form-control input-main" id="subject" placeholder={"Subject"}/>
                                </div>


                                <div className="mb-3">
                                    <label className="form-label b-text">Feature Description (Be as Descriptive as Possible)</label><br></br>
                                    <textarea className="form-control input-main" id="featureDescription" rows="3" placeholder={"Enter feature description here."}></textarea>
                                </div>

                                <Button text={"SUBMIT"} color={"red"}/>
                            </form>
                        </div>
                    </Card>

                    <Card title={"Trending Feature Submissions"} iconClass={"fas fa-chart-line fa-lg"}>
                        <label className="form-label b-text">Sort by Topic</label><br></br>

                        <div className={"row d-flex align-items-center"}>
                            <div className="mb-3 col-sm-6">
                                <select className="form-select input-main" id="createdFeatureTopic">
                                    <option selected>Select a Topic</option>
                                    <option value="1">User Interface</option>
                                    <option value="2">User Experience</option>
                                    <option value="3">Performance</option>
                                    <option value="4">Other</option>
                                </select>
                            </div>

                            <div className={"col-sm-6 d-flex justify-content-end"}>
                                <nav aria-label="Page navigation">
                                    <ul className="pagination custom-pagination">
                                        <li className="page-item"><a className="page-link" href={"/contact/list/"}>1</a>
                                        </li>
                                        <li className="page-item"><a className="page-link" href={"/contact/list/"}>2</a>
                                        </li>
                                        <li className="page-item active"><a className="page-link"
                                                                            href={"/contact/list/"}>3</a></li>
                                        <li className="page-item"><a className="page-link" href={"/contact/list/"}>4</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>

                        <PageDividerHr/>

                        {renderFeatureRequest(
                            1,
                            15,
                            "Subject / Title Here",
                            "Here is where the feature submission would go. Here is where the feature submission would go. Here is where the feature submission would go. Here is where the feature submission would go. Here is where the feature submission would go. Here is where the feature submission would go. Here is where the feature submission would go. Here is where the feature submission would go."
                        )}

                        {renderFeatureRequest(
                            2,
                            12,
                            "Subject / Title Here",
                            "Here is where the feature submission would go. Here is where the feature submission would go. Here is where the feature submission would go."
                        )}

                        {renderFeatureRequest(
                            3,
                            6,
                            "Subject / Title Here",
                            "Here is where the feature submission would go. Here is where the feature submission would go. Here is where the feature submission would go. Here is where the feature submission would go. Here is where the feature submission would go. Here is where the feature submission would go. Here is where the feature submission would go. Here is where the feature submission would go."
                        )}

                        <div className={"row"}>
                            <div className={"d-flex justify-content-end"}>
                                <nav aria-label="Page navigation">
                                    <ul className="pagination custom-pagination">
                                        <li className="page-item"><a className="page-link" href={"/contact/list/"}>1</a>
                                        </li>
                                        <li className="page-item"><a className="page-link" href={"/contact/list/"}>2</a>
                                        </li>
                                        <li className="page-item active"><a className="page-link"
                                                                            href={"/contact/list/"}>3</a></li>
                                        <li className="page-item"><a className="page-link" href={"/contact/list/"}>4</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </Card>
                </div>

                <div className={"col-sm-5"}>
                    <Card title={"Feature Request Stats"} iconClass={"fas fa-chart-bar fa-lg"}>

                        <div className={"stats-container"}>
                            <div className={"stat-item"}>
                                <div className={"stat-icon-wrapper stat-icon-one"}>
                                    <i className="fas fa-paper-plane"></i>
                                </div>
                                <div className={"text-center"}>
                                    <h3>794</h3>
                                    <span className={"text-gray"}>Total<br/> Submissions</span>
                                </div>
                            </div>

                            <div className={"stat-item"}>
                            <div className={"stat-icon-wrapper stat-icon-two"}>
                                    <i className="fas fa-circle-check"></i>
                                </div>
                                <div className={"text-center"}>
                                    <h3>794</h3>
                                    <span className={"text-gray"}>Completed<br />Submissions</span>
                                </div>
                            </div>

                            <div className={"stat-item"}>
                                <div className={"stat-icon-wrapper stat-icon-three"}>
                                    <i className="fas fa-spinner"></i>
                                </div>
                                <div className={"text-center"}>
                                    <h3>794</h3>
                                    <span className={"text-gray"}>In Progress<br/> Submissions</span>
                                </div>
                            </div>
                        </div>
                    </Card>

                    <Card title={"Approved Feature Submissions"} iconClass={"fas fa-check fa-lg"}>

                        <div className="d-flex justify-content-between mb-1">
                            <div className={"d-flex justify-content-start"}>
                                <a href={"#"} className={"link"}>Subject of Submission Goes Here and...</a>
                            </div>

                            <div className={"d-flex justify-content-end"}>
                                <span className={"text-gray me-1"}>Submitted On:</span>
                                <span className={"text-dark-gray"}> 06/15/2023</span>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between mb-1">
                            <div className={"d-flex justify-content-start"}>
                                <a href={"#"} className={"link"}>Subject of Submission Goes Here and...</a>
                            </div>

                            <div className={"d-flex justify-content-end"}>
                                <span className={"text-gray me-1"}>Submitted On:</span>
                                <span className={"text-dark-gray"}> 06/15/2023</span>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between mb-1">
                            <div className={"d-flex justify-content-start"}>
                                <a href={"#"} className={"link"}>Subject of Submission Goes Here and...</a>
                            </div>

                            <div className={"d-flex justify-content-end"}>
                                <span className={"text-gray me-1"}>Submitted On:</span>
                                <span className={"text-dark-gray"}> 06/15/2023</span>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between">
                            <div className={"d-flex justify-content-start"}>
                                <a href={"#"} className={"link"}>Subject of Submission Goes Here and...</a>
                            </div>

                            <div className={"d-flex justify-content-end"}>
                                <span className={"text-gray me-1"}>Submitted On:</span>
                                <span className={"text-dark-gray"}> 06/15/2023</span>
                            </div>
                        </div>

                    </Card>

                    <Card title={"All Feature Submissions"} iconClass={"fas fa-list fa-lg"}>

                        <div className="d-flex justify-content-between mb-1">
                            <div className={"d-flex justify-content-start"}>
                                <a href={"#"} className={"link"}>Subject of Submission Goes Here and...</a>
                            </div>

                            <div className={"d-flex justify-content-end"}>
                                <span className={"text-gray me-1"}>Submitted On:</span>
                                <span className={"text-dark-gray"}> 06/15/2023</span>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between mb-1">
                            <div className={"d-flex justify-content-start"}>
                                <a href={"#"} className={"link"}>Subject of Submission Goes Here and...</a>
                            </div>

                            <div className={"d-flex justify-content-end"}>
                                <span className={"text-gray me-1"}>Submitted On:</span>
                                <span className={"text-dark-gray"}> 06/15/2023</span>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between mb-1">
                            <div className={"d-flex justify-content-start"}>
                                <a href={"#"} className={"link"}>Subject of Submission Goes Here and...</a>
                            </div>

                            <div className={"d-flex justify-content-end"}>
                                <span className={"text-gray me-1"}>Submitted On:</span>
                                <span className={"text-dark-gray"}> 06/15/2023</span>
                            </div>
                        </div>

                        <div className="d-flex justify-content-between">
                            <div className={"d-flex justify-content-start"}>
                                <a href={"#"} className={"link"}>Subject of Submission Goes Here and...</a>
                            </div>

                            <div className={"d-flex justify-content-end"}>
                                <span className={"text-gray me-1"}>Submitted On:</span>
                                <span className={"text-dark-gray"}> 06/15/2023</span>
                            </div>
                        </div>

                    </Card>
                </div>
            </div>
        </div>
    );
}

export default FeatureRequest;
import React, { useState, useEffect } from 'react';
import PageTitle from "../elements/PageTitle";
import Button from "../elements/Button";
import GeneralSelectBox from "../elements/GeneralSelectBox";
import Card from "../elements/Card";
import PageDividerHr from "../elements/PageDividerHr";
import FolderAccordion from "../elements/FolderAccordion";
import Table from "../elements/Table";
import Modal from "../elements/Modal";

const MediaListHome = () => {
    const [selectedFolder, setSelectedFolder] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 1199);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 1199);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleFolderSelect = (folder) => {
        setSelectedFolder(folder);
        if (isMobile) {
            setShowModal(true);
        }
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedFolder(null);
    };

    const listSortOptions = [
        { value: 'az', label: 'A-Z' }
    ];

    const folderData = [
        {
            id: 'audio',
            title: 'Audio',
            icon: 'fa-volume-up',
            children: []
        },
        {
            id: 'documents',
            title: 'Documents',
            icon: 'fa-folder',
            children: [
                {
                    id: 'brand-assets',
                    title: 'Brand Assets',
                    icon: 'fa-folder',
                    children: [
                        {
                            id: 'logo-files',
                            title: 'Logo Files',
                            icon: 'fa-file'
                        },
                        {
                            id: 'subfolder',
                            title: 'Subfolder Here',
                            icon: 'fa-folder',
                            children: []
                        }
                    ]
                },
                {
                    id: 'dealer-assets',
                    title: 'Dealer Assets',
                    icon: 'fa-file'
                },
                {
                    id: 'digital-assets',
                    title: 'Digital Assets',
                    icon: 'fa-file'
                }
            ]
        },
        {
            id: 'pictures',
            title: 'Pictures',
            icon: 'fa-image',
            children: [
                {
                    id: 'main-images',
                    title: 'Main Images',
                    icon: 'fa-folder',
                    children: [
                        {
                            id: 'image-1',
                            icon: 'fa-file',
                            title: 'File Name Goes Here',
                            type: 'JPEG',
                            size: '3.341 KB',
                            used_in: 'Message',
                            uploaded_by: 'Name Here',
                            date_modified: '8/21/2024 8:40 AM'
                        },
                        {
                            id: 'image-2',
                            icon: 'fa-file',
                            title: 'File Name Goes Here',
                            type: 'JPEG',
                            size: '3.341 KB',
                            used_in: 'Message',
                            uploaded_by: 'Name Here',
                            date_modified: '8/21/2024 8:40 AM'
                        },
                        {
                            id: 'image-3',
                            icon: 'fa-file',
                            title: 'File Name Goes Here',
                            type: 'JPEG',
                            size: '3.341 KB',
                            used_in: 'Message',
                            uploaded_by: 'Name Here',
                            date_modified: '8/21/2024 8:40 AM'
                        },
                        {
                            id: 'image-4',
                            icon: 'fa-file',
                            title: 'File Name Goes Here',
                            type: 'JPEG',
                            size: '3.341 KB',
                            used_in: 'Message',
                            uploaded_by: 'Name Here',
                            date_modified: '8/21/2024 8:40 AM'
                        }
                    ]
                }
            ]
        },
        {
            id: 'uploads',
            title: 'Uploads',
            icon: 'fa-upload',
            children: []
        },
        {
            id: 'videos',
            title: 'Videos',
            icon: 'fa-video',
            children: []
        }
    ];

    const tableData = [
        {
            name: 'File Name Goes Here',
            type: 'JPEG',
            size: '3.341 KB',
            used_in: 'Message',
            uploaded_by: 'Name Here',
            date_modified: '8/21/2024 8:40 AM'
        }
    ];

    const mediaIcon = (type) => {

        const iconMappings = [
            { type: 'JPEG', icon: 'fa-file-image text-purple' },
            { type: 'DOC', icon: 'fa-file text-blue' },
            { type: 'PDF', icon: 'fa-file-pdf text-pink' },
            { type: 'SVG', icon: 'fa-file-image text-pink' },
            { type: 'MP4', icon: 'fa-file-video text-purple' },
            { type: 'MP3', icon: 'fa-file-audio text-orange' },
            { type: 'XLS', icon: 'fa-file-excel text-green' },
            { type: 'PPT', icon: 'fa-file-powerpoint text-orange' },
            { type: 'ZIP', icon: 'fa-file-archive text-gray' },
            { type: 'XML', icon: 'fa-file-code text-green' },
            { type: 'POWERPOINT', icon: 'fa-file-powerpoint text-pink' },
            { type: 'CSV', icon: 'fa-file-csv text-orange' }
        ];

        const foundIcon = iconMappings.find(item => item.type.toUpperCase() === type.toUpperCase());

        return foundIcon ? 'me-2 fa-lg fas '+foundIcon.icon : ' fas fa-file text-muted';
    };

    return (
        <div>
            <PageTitle title="Media Library" />
            <div className="row align-items-center mb-2">
                <div className="col-xl-12 d-flex justify-content-start">
                    <div className={"me-2"}>
                        <Button
                            text="New List"
                            color="red"
                            link="/"
                        />
                    </div>
                    <div className="me-2 container-200">
                        <select className={"form-select input-alt"}>
                            <option value="all">A-Z</option>
                        </select>
                    </div>
                    <div className={"create-folder"}>
                        <i className="fas fa-folder"></i> <span className={"btn-link"}>Create New Folder</span>
                    </div>
                </div>
            </div>
            <PageDividerHr className="mb-2" />

            <div className="row">
                <div className="col-12 col-xl-4 ">
                    <FolderAccordion data={folderData} onSelect={handleFolderSelect}/>
                </div>
                <div className="col-xl-8 col-lg-12 d-none d-xl-block custom-hide-lg">
                    {selectedFolder ? (
                        <Table
                            title={selectedFolder.title}
                            subtitle={`${tableData.length} Files`}
                            data={tableData}
                            columns={[
                                { key: 'name', header: 'Name', render: (row) => <><i className={mediaIcon(row.type)}></i>{row.name}</> },
                                { key: 'type', header: 'Type' },
                                { key: 'size', header: 'Size' },
                                { key: 'used_in', header: 'Used In' },
                                { key: 'uploaded_by', header: 'Who Uploaded' },
                                { key: 'date_modified', header: 'Date Modified' },
                                { key: 'actions', header: '', render: () => <span className={"d-flex justify-content-center"}><i className={"fas fa-times text-red"}></i></span> }
                            ]}
                        />
                    ) : (
                        <Card className="pt-5 pb-5 d-block">
                            <div className="pt-5 pb-4 d-flex justify-content-center align-content-center">
                                <h3 className="text-gray">Select a Folder to View its Contents Here</h3>
                            </div>
                        </Card>
                    )}
                </div>
            </div>

            {isMobile && selectedFolder && (
                <Modal show={showModal} onClose={handleCloseModal} title={selectedFolder.title}>
                    <Table
                        title={selectedFolder.title}
                        subtitle={`${tableData.length} Files`}
                        data={tableData}
                        columns={[
                            { key: 'name', header: 'Name', render: (row) => <><i className={mediaIcon(row.type)}></i>{row.name}</> },
                            { key: 'type', header: 'Type' },
                            { key: 'size', header: 'Size' },
                            { key: 'used_in', header: 'Used In' },
                            { key: 'uploaded_by', header: 'Who Uploaded' },
                            { key: 'date_modified', header: 'Date Modified' },
                            { key: 'actions', header: '', render: () => <span className={"d-flex justify-content-center"}><i className={"fas fa-times text-red"}></i></span> }
                        ]}
                    />
                </Modal>
            )}
        </div>
    );
};

export default MediaListHome;
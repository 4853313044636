import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

const DragDropAttachment = ({ onFilesChange }) => {
    const [files, setFiles] = useState([]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            console.log("Files dropped:", acceptedFiles);
            const newFiles = [...files, ...acceptedFiles.map(file => ({
                name: file.name,
                type: file.type,
                preview: file.type.startsWith('image/') ? URL.createObjectURL(file) : null,
                file: file // Keep the original file object
            }))];
            setFiles(newFiles);
            console.log("Passing files to parent:", newFiles.map(f => f.file));
            onFilesChange(newFiles.map(f => f.file)); // Pass only the file objects to the parent
        }
    });

    useEffect(() => {
        // Clean up the object URLs to avoid memory leaks
        return () => files.forEach(file => file.preview && URL.revokeObjectURL(file.preview));
    }, [files]);

    const handleRemoveFile = (fileIndex) => {
        const newFiles = files.filter((_, index) => index !== fileIndex);
        setFiles(newFiles);
        console.log("Passing updated files to parent after removal:", newFiles.map(f => f.file));
        onFilesChange(newFiles.map(f => f.file)); // Pass only the file objects to the parent
    };

    return (
        <div className="drag-drop-container">
            {/* Drag and Drop area */}
            <div className="drag-drop-area" {...getRootProps()}>
                <input {...getInputProps()} />
                <div className="text-center">
                    <i className="fa fa-plus-circle fa-2x mb-2"></i>
                    <div className="drag-drop-text">Click Here or Drag & Drop</div>
                    <div className="drag-drop-text">to Add a New Document</div>
                </div>
            </div>

            {/* Conditionally render the grid of files/images */}
            {files.length > 0 && (
                <div className="row mt-3">
                    {files.map((file, index) => (
                        <div className="col-6 mb-3" key={index}> {/* Two-column layout */}
                            <div className="gray-box position-relative">
                                {/* If it's an image, show the image, otherwise show a FontAwesome file icon */}
                                {file.preview ? (
                                    <img src={file.preview} alt={file.name} className="img-fluid object-fit-cover" />
                                ) : (
                                    <i className="fa fa-file fa-3x text-muted"></i>
                                )}

                                {/* Remove File button */}
                                <div className="remove-file position-absolute top-0 end-0 p-1">
                                    <i
                                        className="fa fa-times-circle text-danger fa-lg"
                                        onClick={() => handleRemoveFile(index)}
                                        style={{ cursor: 'pointer' }}
                                    ></i>
                                </div>
                            </div>
                            <div className="file-title text-center">{file.name}</div>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
}

export default DragDropAttachment;

import React, {useEffect, useState} from 'react';
import PageTitle from "../elements/PageTitle";
import Button from "../elements/Button";
import PageDividerHr from "../elements/PageDividerHr";
import Card from "../elements/Card";
import DragDropAttachment from "../elements/DragDropAttachment";
import FormDropdown from "../elements/FormDropdown";
import api from "../utlities/api";

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

const ContactCreateForm = () => {
    const [lists, setLists] = useState([]);
    const [setError] = useState(null);
    const [setFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchLists = async () => {
            try {
                const data = await api.get('/ContactList');
                setLists(data);
                setIsLoading(false);
                console.log(data);
            } catch (error) {
                console.error("Error fetching lists:", error);
                setIsLoading(false);
            }
        };

        fetchLists();
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const handleFileChange = (newFiles) => {
        const validFiles = newFiles.filter(file => {
            if (file.size > MAX_FILE_SIZE) {
                setError(`File ${file.name} is too large. Max size is 10MB.`);
                return false;
            }
            return true;
        });
        setFiles(validFiles);
        console.log("Files changed in DragDropAttachment:", validFiles);
    };


    return (
        <div>
            <PageTitle title={"List Settings"} />
            <Button color={"red"} text={"Create List"} />
            <PageDividerHr />
            <div className={"row"}>
                <div className={"col-xl-8 col-lg-12"}>
                    <Card title={"Create New Contact List"} iconClass={"fas fa-fw fa-list fa-lg"}>
                        <div className={"row"}>
                            <div className={"col-sm-6 mb-3"}>
                                <label htmlFor="title" className="form-label b-text">
                                    <span className="text-red">*</span> Title of List
                                </label>
                                <input
                                    type="text"
                                    className="form-control input-main"
                                    name="title"
                                    id="title"
                                    placeholder="Title of List"
                                    required
                                />
                            </div>

                            <div className={"col-sm-6 mb-3"}>
                                <label htmlFor="list" className="form-label b-text">
                                    <span className="text-red">*</span> Sort into Folder:
                                </label>
                                <FormDropdown title={"Select Folder"} options={[{ label: "Option 1", value: "1" },{ label: "Option 2", value: "2" },{ label: "Option 3", value: "3" }]} />
                            </div>

                            <div className={"col-sm-6 mb-3"}>
                                <label htmlFor="list" className="form-label b-text">
                                    <span className="text-red">*</span> Company Access:
                                </label>
                                <FormDropdown title={"Which companies can access this list?"} options={lists}/>
                            </div>

                            <div className={"col-sm-6"}>
                                <label htmlFor="list" className="form-label b-text">
                                    <span className="text-red">*</span> User Access:
                                </label>
                                <FormDropdown title={"Which users can access this list?"} options={[{label: "Option 1", value: "1"}, {label: "Option 2", value: "2"}, {label: "Option 3", value: "3"}]}/>
                            </div>
                        </div>
                    </Card>
                </div>

                <div className={"col-xl-4 col-lg-12"}>
                    <Card title="Upload .CSV File" iconClass="fas fa-fw fa-upload fa-lg">
                        <DragDropAttachment onFilesChange={handleFileChange}/>
                    </Card>
                </div>
            </div>


        </div>
    );
}

export default ContactCreateForm;
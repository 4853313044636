import React from 'react';
import PageTitle from "../elements/PageTitle";
import PageDividerHr from "../elements/PageDividerHr";
import Card from "../elements/Card";
import Button from "../elements/Button";

const HelpDocs = () => {
    return (
        <div>
            <PageTitle title="Help Documents" />
            <PageDividerHr className="mb-2"/>

            <div className={"row faq-page"}>
                <div className={"col-sm-7"}>
                    <Card title={"How can we help?"} iconClass={"fas fa-file fa-lg"}>
                        <span>Dealer Zone is an ever evolving software designed to make business-to-dealer interaction easy and efficient.</span>
                        <span>We know how daunting software can be, so we've created a documentation center to help you get the answers to any questions you may have.</span>

                        <div className="input-group mt-3">
                            <input
                                type="text"
                                className="form-control input-main"
                                name="searchDocCenter"
                                id="searchDocCenter"
                                value="Search Documentation Center"
                            />
                            <span className="input-group-text input-main">
                                <i className="fas fa-search fa-lg"></i>
                            </span>
                        </div>

                        <div className="accordion mt-3" id="faqAccordion">
                            <div className="accordion-item" key={1}>
                                <h2 className="accordion-header">
                                    <button className="accordion-button border-0 faq-title" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true">
                                        Question goes right here?
                                    </button>
                                </h2>
                                <div id="collapse1" className="accordion-collapse collapse show border-0"
                                     data-bs-parent="#faqAccordion">
                                    <div className="accordion-body border-0 faq-content">
                                        The answer to the question would go right here. The answer to the question would
                                        go
                                        right here. The answer to the question would go right here. The answer to the
                                        question would go right here. The answer to the question would go right here.
                                        The
                                        answer to the question would go right here. The answer to the question would go
                                        right here. The answer to the question would go right here.
                                    </div>
                                </div>
                            </div>
                            {[2, 3, 4, 5, 6, 7, 8].map((num) => (
                                <div className="accordion-item" key={num}>
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed border-0 faq-title faq-bord-top"
                                                type="button" data-bs-toggle="collapse"
                                                data-bs-target={`#collapse${num}`}>
                                            Question goes right here?
                                        </button>
                                    </h2>
                                    <div id={`collapse${num}`} className="accordion-collapse collapse border-0"
                                         data-bs-parent="#faqAccordion">
                                        <div className="accordion-body border-0 faq-content">
                                            The answer to the question would go right here. The answer to the question
                                            would
                                            go right here. The answer to the question would go right here. The answer to
                                            the
                                            question would go right here. The answer to the question would go right
                                            here.
                                            The answer to the question would go right here. The answer to the question
                                            would
                                            go right here. The answer to the question would go right here.
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>

                        <hr className={"mt-0 pt-0"}/>

                        <div className={"row mt-5"}>
                            <span className={"h3"}>Still can't find what you're looking for?</span>

                        </div>

                        <div className="input-group mt-1">
                            <input
                                type="text"
                                className="form-control input-main"
                                name="searchDocCenter"
                                id="searchDocCenter"
                                value="Search Documentation Center"
                            />
                            <span className="input-group-text input-main">
                                <i className="fas fa-search fa-lg"></i>
                            </span>
                        </div>
                    </Card>
                </div>

                <div className={"col-sm-5"}>
                    <Card title={"Current Patch: 1.1.23"} iconClass={"fas fa-code fa-lg"}>
                        <a className={"link"} href={'#'}>View 1.1.23 Patch Notes</a>

                        <div className={"mt-3"}>
                            <span>Dealer Zone is updated regularly with new features, enhancements, and performance improvements.</span>
                            <span>To see a full list of what we're currently working on, or to submit a new feature idea, click the link below.</span>
                        </div>

                        <div className={"mt-3 mb-2"}>
                            <Button text={"FEATURE REQUEST"} color={"red"}/>
                        </div>

                        <PageDividerHr/>

                        <span className={"h3"}>Previous Patches</span><br/>

                        <div className={"mt-2 d-flex flex-column gap-2"}>
                            <div>
                                <a className={"link"} href={'#'}>0.4.23</a>
                            </div>

                            <div>
                                <a className={"link"} href={'#'}>0.3.23</a>
                            </div>

                            <div>
                                <a className={"link"} href={'#'}>0.2.23</a>
                            </div>

                            <div>
                                <a className={"link"} href={'#'}>0.1.23</a>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    );
}

const styles = `
  .faq-page .accordion-button:not(.collapsed) {
    background-color: white;
    color: black;
  }

  .faq-page .accordion-button:focus {
    box-shadow: none;
  }

  .faq-page .accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }

  .faq-page .form-check-input:checked {
    background-color: #dc3545;
    border-color: #dc3545;
  }

  .faq-page .btn-danger {
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  .faq-page .faq-title {
    font-size: 20px;
    font-weight: bold;
    color: #333333;
  }
  .faq-page .faq-content {
    font-size: 14px;
    color: #929292;
  }
  .faq-page .faq-bord-top {
    border-top: 1px solid #D2D2D2 !important;
  }
  .faq-page .faq-border-right {
        border-right: 1px solid #D2D2D2 !important;
  }
  .faq-page .faq-padd-left {
        padding-left: 30px;
  }
  .faq-page .accordion-body {
    padding: 0px;
    padding-bottom: 20px;
    }
    .faq-page .accordion-button {
        padding-left: 0px;
    }
    .faq-page .accordion-button {
       
    }
    .faq-page .faq-filter-title {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
    }
`;

export default () => (
    <>
        <style>{styles}</style>
        <HelpDocs />
    </>
);
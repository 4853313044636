const API_BASE_URL = 'https://toroportal.volanosoftware.com/api/v1';

const api = {
    get: async (endpoint) => {
        try {
            const response = await fetch(`${API_BASE_URL}${endpoint}`);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            return data;
        } catch (error) {
            console.error("Could not fetch data:", error);
            throw error;
        }
    }
};

export default api;
import React from 'react';
import Card from "./Card";

const Shortcuts = ({ items, icon }) => {
    return (
        <Card title={"Shortcuts"} id={"shortcut-card"} iconClass={icon} headerSeparator={false}>
            <div className="mt-4">
                <div className="row d-flex justify-content-center justify-content-md-between justify-content-xl-center">
                    {items.map((item, index) => (
                        <div key={index} className="col-xl-6 col-md-2 col-sm-6 mb-3 shortcut-icon-container">
                            <a href={item.link} className="text-decoration-none">
                                <div className="shortcut-icon">
                                    <i className={`${item.icon} fa-lg`}></i>
                                </div>
                                <span className="b-text">{item.text}</span>
                            </a>
                        </div>
                    ))}
                </div>
            </div>
        </Card>
    );
};

export default Shortcuts;
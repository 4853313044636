import React, { useState, useRef } from 'react';
import Button from '../elements/Button';

const FileUpload = ({ label = 'Upload a File', button_text = 'CHOOSE A FILE', name = 'file_uploader', multiple = false }) => {
    const [fileNames, setFileNames] = useState([]); // Store multiple file names in an array
    const fileInputRef = useRef(null);

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const files = Array.from(e.target.files); // Get all selected files
        if (files.length > 0) {
            const fileNamesArray = files.map(file => file.name);
            setFileNames(prevFileNames => multiple ? [...prevFileNames, ...fileNamesArray] : fileNamesArray); // Handle multiple or single files
        }
    };

    const handleRemoveFile = (index) => {
        const updatedFiles = [...fileNames];
        updatedFiles.splice(index, 1); // Remove the selected file by index
        setFileNames(updatedFiles);
        if (updatedFiles.length === 0) {
            fileInputRef.current.value = ''; // Reset input if no files remain
        }
    };

    return (
        <div className="row">
            <label htmlFor={name} className="form-label b-text">
                {label}
            </label>
            <div className="col-sm-8 d-flex align-items-start">
                <div className={"me-2"}>
                    <Button text={button_text} color={"red"} onClick={handleButtonClick} />
                    <input
                        type="file"
                        name={name}
                        id={name}
                        className="d-none"
                        ref={fileInputRef}
                        multiple={multiple} // Enable multiple files if `multiple` is true
                        onChange={handleFileChange}
                    />
                </div>

                <div className="file-upload-container">
                    {fileNames.length > 0 && (
                        <ul className="file-list p-0 m-0" style={{ listStyleType: 'none' }}>
                            {fileNames.map((fileName, index) => (
                                <li key={index} className="file-info d-flex justify-content-between align-items-center">
                                    <span>{fileName}</span>
                                    <button className="btn btn-link text-danger" onClick={() => handleRemoveFile(index)}>
                                        <i className="fas fa-times"></i>
                                    </button>
                                </li>
                            ))}
                        </ul>
                    )}
                </div>
            </div>
        </div>
    );
};

export default FileUpload;
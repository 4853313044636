import React, { useState, useEffect } from 'react';
import PageTitle from '../elements/PageTitle';
import Button from '../elements/Button';
import Card from '../elements/Card';
import FormDropdown from "../elements/FormDropdown";
import TagList from "../api/TagList";
import DragDropAttachment from "../elements/DragDropAttachment";
import ContentBuilder from "../custom/MessageContentBuilder";
import uploadAttachments from '../process/uploadAttachments';
import PageDividerHr from "../elements/PageDividerHr";
import api from "../utlities/api";

const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MB

const MessageCreateForm = () => {
    const [lists, setLists] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [files, setFiles] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState(null);
    const [formData, setFormData] = useState({
        title: '',
        publishDate: '2024-09-08',
        archiveDate: '2024-10-08',
        lockMessage: '0',
        markAsRead: '0',
        notifyVia: 'none'
    });

    useEffect(() => {
        const fetchLists = async () => {
            try {
                const response = await api.get('/ContactList');
                const data = response.map(item => ({
                    value: item.id.toString(),
                    label: item.name
                }));
                setLists(data);
                setIsLoading(false);
            } catch (error) {
                console.error("Error fetching lists:", error);
                setIsLoading(false);
            }
        };

        fetchLists();
    }, []);

    if (isLoading) {
        return <div>Loading...</div>;
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handleFileChange = (newFiles) => {
        const validFiles = newFiles.filter(file => {
            if (file.size > MAX_FILE_SIZE) {
                setError(`File ${file.name} is too large. Max size is 10MB.`);
                return false;
            }
            return true;
        });
        setFiles(validFiles);
        console.log("Files changed in DragDropAttachment:", validFiles);
    };

    const handleSubmit = async (isDraft = false) => {
        console.log('Submitting form...');
        setIsSubmitting(true);
        setError(null);
        setSuccessMessage(null);
        try {
            let uploadedFiles = [];
            if (files.length > 0) {
                console.log("Uploading attachments...");
                uploadedFiles = await uploadAttachments(files);
                console.log("Uploaded files:", uploadedFiles);
                if (uploadedFiles.length === 0) {
                    throw new Error("File upload failed. This might be due to CORS issues or invalid file objects. Please check the console for detailed error messages and ensure CORS is properly configured on your Azure Blob Storage account.");
                } else if (uploadedFiles.length < files.length) {
                    setError(`Only ${uploadedFiles.length} out of ${files.length} files were uploaded successfully. Please check the console for detailed error messages.`);
                }
            }

            const submitData = {
                ...formData,
                attachments: uploadedFiles,
                isDraft
            };
            console.log('Form data to submit:', submitData);
            // Send the form data to your API
            // const response = await axios.post('/api/v1/Messages', submitData);
            // console.log('Message saved successfully:', response.data);

            setSuccessMessage(isDraft ? "Draft saved successfully." : "Message submitted successfully.");
            // Clear the form or redirect the user as needed
        } catch (error) {
            console.error('Error submitting form:', error);
            if (error.message.includes('CORS')) {
                setError("A CORS error occurred. Please ensure CORS is properly configured on your Azure Blob Storage account. Check the console for more details.");
            } else {
                setError(error.message || "An error occurred while submitting the form. Please check the console for detailed error messages and try again.");
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div>
            <PageTitle title="New Message" />
            {error && (
                <div className="alert alert-danger" role="alert">
                    {error}
                </div>
            )}
            {successMessage && (
                <div className="alert alert-success" role="alert">
                    {successMessage}
                </div>
            )}
            <div className="row align-items-center mb-2">
                <div className="col-12 d-flex justify-content-between">
                    <Button
                        text="Save Draft"
                        color="blue"
                        onClick={() => {
                            console.log("Save Draft button clicked");
                            handleSubmit(true);
                        }}
                        disabled={isSubmitting}
                    />
                    <Button
                        text="Submit"
                        color="red"
                        onClick={() => {
                            console.log("Submit button clicked");
                            handleSubmit(false);
                        }}
                        disabled={isSubmitting}
                        noMargin={true}
                    />
                </div>
            </div>
            <PageDividerHr className="mb-2"/>
            <div className="row">
                <div className="col-xl-8 col-lg-12">
                    <Card title="Send Settings" iconClass="fas fa-fw fa-envelope fa-lg">
                        <div className="row">
                            <div className="col-xl-6 col-lg-12 mb-3">
                                <label htmlFor="title" className="form-label b-text">
                                    <span className="text-red">*</span> Title of Message
                                </label>
                                <input
                                    type="text"
                                    className="form-control input-main"
                                    name="title"
                                    id="title"
                                    placeholder="Title of Message"
                                    value={formData.title}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="col-xl-6 col-lg-12 mb-3">
                                <label htmlFor="list" className="form-label b-text">
                                    <span className="text-red">*</span> List(s) to Send To:
                                </label>
                                <FormDropdown options={lists} title="Choose List"/>
                            </div>
                            <div className="col-xl-3 col-lg-6 mb-3">
                                <label htmlFor="publishDate" className="form-label b-text">Publish On:</label>
                                <input
                                    type="date"
                                    className="form-control input-main"
                                    name="publishDate"
                                    id="publishDate"
                                    value={formData.publishDate}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                            <div className="col-xl-3 col-lg-6 mb-3">
                                <label htmlFor="archiveDate" className="form-label b-text">Archive On:</label>
                                <input
                                    type="date"
                                    className="form-control input-main"
                                    name="archiveDate"
                                    id="archiveDate"
                                    value={formData.archiveDate}
                                    onChange={handleInputChange}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-xl-3 col-md-12 pb-3 pb-xl-0">
                                <label className="form-label b-text">Lock this Message?</label><br></br>
                                <div className="d-inline-flex">
                                    <div className="form-check me-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="lockYes"
                                            name="lockMessage"
                                            value="1"
                                            checked={formData.lockMessage === '1'}
                                            onChange={handleInputChange}
                                        />
                                        <label className="form-check-label" htmlFor="lockYes">Yes</label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="lockNo"
                                            name="lockMessage"
                                            value="0"
                                            checked={formData.lockMessage === '0'}
                                            onChange={handleInputChange}
                                        />
                                        <label className="form-check-label" htmlFor="lockNo">No</label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-12 pb-3 pb-xl-0">
                                <label className="form-label b-text">Require 'Mark as Read'?</label><br/>
                                <div className="d-inline-flex">
                                    <div className="form-check me-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="markAsReadYes"
                                            name="markAsRead"
                                            value="1"
                                            checked={formData.markAsRead === '1'}
                                            onChange={handleInputChange}
                                        />
                                        <label className="form-check-label" htmlFor="markAsReadYes">Yes</label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="markAsReadNo"
                                            name="markAsRead"
                                            value="0"
                                            checked={formData.markAsRead === '0'}
                                            onChange={handleInputChange}
                                        />
                                        <label className="form-check-label" htmlFor="markAsReadNo">No</label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-xl-3 col-md-12">
                                <label className="form-label b-text">Notify via:</label><br/>
                                <div className="d-inline-flex">
                                <div className="form-check me-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="notifyNone"
                                            name="notifyVia"
                                            value="none"
                                            checked={formData.notifyVia === 'none'}
                                            onChange={handleInputChange}
                                        />
                                        <label className="form-check-label" htmlFor="notifyNone">None</label>
                                    </div>
                                    <div className="form-check me-3">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="notifyEmail"
                                            name="notifyVia"
                                            value="email"
                                            checked={formData.notifyVia === 'email'}
                                            onChange={handleInputChange}
                                        />
                                        <label className="form-check-label" htmlFor="notifyEmail">Email</label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            id="notifyText"
                                            name="notifyVia"
                                            value="text"
                                            checked={formData.notifyVia === 'text'}
                                            onChange={handleInputChange}
                                        />
                                        <label className="form-check-label" htmlFor="notifyText">Text</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                    <ContentBuilder/>
                </div>
                <div className="col-xl-4 col-lg-12">
                    <Card title="Tags" iconClass="fas fa-fw fa-tags fa-lg">
                        <TagList/>
                    </Card>
                    <Card title="Attachments" iconClass="fas fa-fw fa-paperclip fa-lg">
                        <DragDropAttachment onFilesChange={handleFileChange} />
                    </Card>
                </div>
            </div>
        </div>
    );
}

export default MessageCreateForm;

// Just the output of the header of each page
import React from 'react';

function PageDividerHr({ className, noPadding = false }) {

    let paddingClass;

    if(noPadding){
        paddingClass = "pt-0 pb-0 mb-0 mt-0";
    }

    return (
        <div className={`row ${className}`}>
            <div className="col-12">
                <hr className={`${paddingClass}`}/>
            </div>
        </div>
    );
}

export default PageDividerHr
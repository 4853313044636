import React from 'react';
import PageTitle from "../elements/PageTitle";
import PageDividerHr from "../elements/PageDividerHr";
import Card from "../elements/Card";
import Button from "../elements/Button";

const FAQ = () => {
    return (
        <div>
            <PageTitle title="FAQ" />
            <PageDividerHr className="mb-2"/>
            <Card>
                <div className="row mt-4 faq-page">
                    <div className="col-md-2 faq-border-right">
                        <h5 className="mb-3 faq-filter-title">Filter By:</h5>
                        <div className="form-check mb-2">
                            <input className="form-check-input" type="radio" name="filterOptions" id="option1" />
                            <label className="form-check-label" htmlFor="option1">
                                Topic Goes Here
                            </label>
                        </div>
                        <div className="form-check mb-2">
                            <input className="form-check-input" type="radio" name="filterOptions" id="option2" defaultChecked />
                            <label className="form-check-label" htmlFor="option2">
                                Topic Goes Here
                            </label>
                        </div>
                        <div className="form-check mb-2">
                            <input className="form-check-input" type="radio" name="filterOptions" id="option3" />
                            <label className="form-check-label" htmlFor="option3">
                                Topic Goes Here
                            </label>
                        </div>
                        <div className="form-check mb-2">
                            <input className="form-check-input" type="radio" name="filterOptions" id="option4" />
                            <label className="form-check-label" htmlFor="option4">
                                Topic Goes Here
                            </label>
                        </div>
                        <div className="form-check mb-2">
                            <input className="form-check-input" type="radio" name="filterOptions" id="option5" />
                            <label className="form-check-label" htmlFor="option5">
                                Topic Goes Here
                            </label>
                        </div>
                    </div>
                    <div className="col-md-10">
                        <div className="accordion faq-padd-left" id="faqAccordion">
                            <div className="accordion-item" key={1}>
                                <h2 className="accordion-header">
                                    <button className="accordion-button border-0 faq-title" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#collapse1" aria-expanded="true">
                                        Question goes right here?
                                    </button>
                                </h2>
                                <div id="collapse1" className="accordion-collapse collapse show border-0"
                                     data-bs-parent="#faqAccordion">
                                    <div className="accordion-body border-0 faq-content">
                                        The answer to the question would go right here. The answer to the question would go
                                        right here. The answer to the question would go right here. The answer to the
                                        question would go right here. The answer to the question would go right here. The
                                        answer to the question would go right here. The answer to the question would go
                                        right here. The answer to the question would go right here.
                                    </div>
                                </div>
                            </div>
                            {[2, 3, 4, 5, 6, 7, 8].map((num) => (
                                <div className="accordion-item" key={num}>
                                    <h2 className="accordion-header">
                                        <button className="accordion-button collapsed border-0 faq-title faq-bord-top"
                                                type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${num}`}>
                                            Question goes right here?
                                        </button>
                                    </h2>
                                    <div id={`collapse${num}`} className="accordion-collapse collapse border-0"
                                         data-bs-parent="#faqAccordion">
                                        <div className="accordion-body border-0 faq-content">
                                            The answer to the question would go right here. The answer to the question would
                                            go right here. The answer to the question would go right here. The answer to the
                                            question would go right here. The answer to the question would go right here.
                                            The answer to the question would go right here. The answer to the question would
                                            go right here. The answer to the question would go right here.
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="mt-4 faq-padd-left pt-2">
                            <p className="mb-2 faq-filter-title">Still have questions?</p>
                            <Button text="Contact Us" color="red"/>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    );
};

// Custom CSS to match the design more closely
const styles = `
  .faq-page .accordion-button:not(.collapsed) {
    background-color: white;
    color: black;
  }

  .faq-page .accordion-button:focus {
    box-shadow: none;
  }

  .faq-page .accordion-button::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  }

  .faq-page .form-check-input:checked {
    background-color: #dc3545;
    border-color: #dc3545;
  }

  .faq-page .btn-danger {
    background-color: #dc3545;
    border-color: #dc3545;
  }
  
  .faq-page .faq-title {
    font-size: 20px;
    font-weight: bold;
    color: #333333;
  }
  .faq-page .faq-content {
    font-size: 14px;
    color: #929292;
  }
  .faq-page .faq-bord-top {
    border-top: 1px solid #D2D2D2 !important;
  }
  .faq-page .faq-border-right {
        border-right: 1px solid #D2D2D2 !important;
  }
  .faq-page .faq-padd-left {
        padding-left: 30px;
  }
  .faq-page .accordion-body {
    padding: 0px;
    padding-bottom: 20px;
    }
    .faq-page .accordion-button {
        padding-left: 0px;
    }
    .faq-page .accordion-button {
       
    }
    .faq-page .faq-filter-title {
        font-size: 16px;
        font-weight: bold;
        color: #333333;
    }
`;

export default () => (
    <>
        <style>{styles}</style>
        <FAQ />
    </>
);
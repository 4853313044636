import React from 'react';
import PageTitle from '../elements/PageTitle';
import Card from '../elements/Card';
import PageDividerHr from '../elements/PageDividerHr';
import Button from '../elements/Button';

const Contact = () => {

    return (
        <div>
            <PageTitle title={"Contact Info"} />

            <Card>
                <div className={"row"}>
                    <div className={"col-sm-2 border-end-lg"}>
                        <div className={"mt-3"}>

                            <div className="d-flex flex-column gap-2">
                                <label className={"b-text"}>General Contact Info</label>
                                <div>310 E. University Drive</div>
                                <div>McKinney, TX 75069</div>
                                <div>T: 972.446.0760</div>
                                <div>F: 972.446.0765</div>
                                <div>Hours: Mon-Fri 8:30AM - 5:30PM CT</div>
                            </div>

                            <PageDividerHr/>

                            <div className="d-flex flex-column gap-2">
                                <label className={"b-text"}>Sales</label>
                                <div>Steven Salesguy</div>
                                <div>s.salesguy@hisun.com</div>
                                <div>T: 555.555.5555</div>
                                <div>C: 555.555.5555</div>
                                <div>F: 555.555.5555</div>
                            </div>

                            <PageDividerHr/>

                            <div className="d-flex flex-column gap-2">
                                <label className={"b-text"}>Warranty</label>
                                <div>Steven Warrantyguy</div>
                                <div>s.warrantyguy@hisun.com</div>
                                <div>T: 555.555.5555</div>
                                <div>C: 555.555.5555</div>
                                <div>F: 555.555.5555</div>
                            </div>

                            <PageDividerHr/>

                            <div className="d-flex flex-column gap-2">
                                <label className={"b-text"}>Parts</label>
                                <div>Steven Partsguy</div>
                                <div>s.partsguy@hisun.com</div>
                                <div>T: 555.555.5555</div>
                                <div>C: 555.555.5555</div>
                                <div>F: 555.555.5555</div>
                            </div>
                        </div>
                    </div>

                    <div className={"col-sm-10"}>
                        <div className={"mt-3"}>
                            <div className={"b-text"}>Have a question? Check out our FAQ section.</div>
                            <div className={"col-sm-3 mt-2 mb-4"}>
                                <Button color={"red"} text={"FAQ"}/>
                            </div>
                            <PageDividerHr/>

                            <div className={"row mt-4 mb-3"}>
                                <div className={"col-sm-3"}>
                                    <label htmlFor="first_name" className="form-label b-text">
                                        <span className="text-red">*</span> First Name
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control input-main"
                                        name="first_name"
                                        id="first_name"
                                        placeholder="First Name"
                                        value={""}
                                        required
                                    />
                                </div>

                                <div className={"col-sm-3"}>
                                    <label htmlFor="last_name" className="form-label b-text">
                                        <span className="text-red">*</span> Last Name
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control input-main"
                                        name="last_name"
                                        id="last_name"
                                        placeholder="Last Name"
                                        value={""}
                                        required
                                    />
                                </div>

                                <div className={"col-sm-3"}>
                                    <label htmlFor="email" className="form-label b-text">
                                        <span className="text-red">*</span> Email
                                    </label>
                                    <input
                                        type="email"
                                        className="form-control input-main"
                                        name="email"
                                        id="email"
                                        placeholder="Email"
                                        value={""}
                                        required
                                    />
                                </div>

                                <div className={"col-sm-3"}>
                                    <label htmlFor="phone" className="form-label b-text">
                                        <span className="text-red">*</span> Phone
                                    </label>
                                    <input
                                        type="text"
                                        className="form-control input-main"
                                        name="phone"
                                        id="phone"
                                        placeholder="Phone"
                                        value={""}
                                        required
                                    />
                                </div>
                            </div>

                            <div className={"row mb-3"}>
                                <div className={"col-sm-3"}>
                                    <label htmlFor="other_item_here" className="form-label b-text">
                                        <span className="text-red">*</span> Other Item Here
                                    </label>
                                    <select className="form-select input-main" name={"other_item_here"}
                                            id={"other_item_here"}>
                                        <option value={""} selected>Choose Example</option>
                                        <option value="1">Example 1</option>
                                        <option value="2">Example 2</option>
                                        <option value="3">Example 3</option>
                                    </select>
                                </div>

                                <div className={"col-sm-9"}>
                                    <label htmlFor="subject" className="form-label b-text">
                                        <span className="text-red">*</span> What is this in regards to?
                                    </label>
                                    <select className="form-select input-main" name={"subject"} id="subject">
                                        <option value={""} selected>Choose Subject</option>
                                        <option value="warranty">Warranty</option>
                                        <option value="parts">Parts</option>
                                        <option value="co-op">Co-op</option>
                                    </select>
                                </div>
                            </div>

                            <div className={"row"}>

                                <div className={"col-sm-12"}>
                                    <label htmlFor="description" className="form-label b-text">
                                        <span className="text-red">*</span> What's on your mind?
                                    </label>
                                    <textarea
                                        className="form-control input-main"
                                        name="description"
                                        id="description"
                                        placeholder="Enter question or comment here"
                                        rows={5}
                                        value={""}
                                        required
                                    />
                                </div>

                                <div className={"col-sm-4 mt-3"}>
                                    <Button color={"red"} text={"SUBMIT"}/>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
            </Card>
        </div>
    );
}

export default Contact;